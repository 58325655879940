// ==========================================================================
// Blog
// ==========================================================================
//
// Table of Contents:
//
//  1. Item
//  2. Select
//  3. Blog detail
//  4. Gallery
//  5. CMS content
//  6. Share
//	7. Aside block
//	8. Artículos relacionados
//	9. Newsletter



// 1. Item
// ==========================================================================
.list-blog {
	@media (min-width: 450px) {
		&:not(.no-mosaiker){
			column-count: 2;
			column-gap: 3rem;
		}
		&.no-mosaiker {
			display: grid;
			grid-column-gap: 3rem;
			grid-template-columns: repeat(2, calc(50% - 3rem));
		}
	}

	@media(min-width: 993px) {
		&:not(.no-mosaiker){
			column-count: 4;
			column-gap: 3rem;
		}
		&.no-mosaiker {
			display: grid;
			grid-column-gap: 3rem;
			grid-template-columns: repeat(4, calc(25% - 3rem));
		}
	}
	.blog-item {
		margin-bottom: 5rem;
		display: inline-block;
		width: 100%;
		.category {
			position: relative;
			margin-bottom: 2rem;
			width: auto;
			z-index: 10;
			background: $primary;
			border-radius: 2.3rem;
			font-size: 1.5rem;
			text-transform: uppercase;
			height: 3.1rem;
			line-height: 3.1rem;
			padding: 0 2rem;
			font-weight: 400;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
		}
		&__img {
			margin-bottom: 4.4rem;
			.category {
				position: absolute;
				margin-bottom: 0;
				left: 2rem;
				bottom: 2rem;
				max-width: calc(100% - 4rem);
			}
		}
		.__sup{
			@media(max-width: 1199.98px) {
				display: none;
			}
		}
		.__down{
			@media(min-width: 1200px) {
				display: none;
			}
		}
	}
	&.related-list-blog {
		.blog-item {
			margin-bottom: 2rem;
		}
	}
}


// 2. Select
// ==========================================================================

.blog-select {
	margin-bottom: 3.6rem;
	@media (min-width: 601px) {
		& > li {
			width: 220px;
			margin-left: 2rem;
			margin-right: 2rem;
			&:first-child { margin-left: 0; }
			&:last-child { margin-right: 0; }
		}
	}
}
.blog-d-select {
	margin-bottom: 4rem;
}


// 3. Blog detail
// ==========================================================================

.blog-d-header-row {
	margin-bottom: 2rem;
	@media (min-width: 993px) {
		margin-bottom: 4rem;
		display: -ms-flexbox;
		display: flex;
	}
}
.blog-d-date-col {
	@media (min-width: 993px) {
		display: -ms-flexbox;
		display: flex;
		padding-right: 2rem;
	}
}
.blog-d-date {
	-ms-flex-direction: column;
	-moz-flex-direction: column;
	flex-direction: column;
	background-color: #000;
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 1;
	color: #fff;
	text-transform: uppercase;
	width: 6rem;
	height: 6rem;
	margin: 0 auto 1rem;
	span {
		display: block;
	}	
	.day { font-size: 1.5rem; }
	@media (min-width: 993px) {
		width: 100%;
		height: auto;
		max-height: 16rem;
		font-size: 1.4rem;
		margin: 0;
		.day { font-size: 2.6rem; }
	}
}
.blog-d-header {
	text-align: center;
	@media (min-width: 993px) {
		text-align: left;
	}
}
.blog-d-category {
	display: block;
	font-weight: 500;
	letter-spacing: (70/1000) * 1rem;
	text-transform: uppercase;
	@media (min-width: 993px) {
		line-height: 1;
	}
	a{
		color: black;
	}
}
.blog-d-title {
	text-transform: initial;
	font-size: 2.4rem;
	font-weight: 500;
	line-height: 1;
	border-bottom: none;
	margin-bottom: 0;
	@media (min-width: 601px) {
		font-size: 6rem;
	}
	@media (min-width: 993px) {
		font-size: 8rem;
	}	
}


// 4. Gallery
// ==========================================================================

.blog-gallery {
	.slick-list {

	}
	.slick-slide {
		height: auto;
		img { width: 100%; }
	}
}


// 5. CMS content
// ==========================================================================

.blog-d-main {
	margin-bottom: 12rem;
}
.blog-d-content {
	font-size: 1.6rem;
	line-height: (35/20);
	margin-bottom: 1em;
	//padding-left: 9.6rem;
	//padding-right: 9.6rem;
	letter-spacing: (20/1000) * 1em;
	a {
		color: inherit;
		text-decoration: underline;
	}
	img, .lazyYT {
		margin-bottom: 1em;
	}
	@media (max-width: 992px) {
		&:last-child:not(.empty-content) { margin-bottom: 3.5rem; }
	}
	@media (min-width: 993px) {
		font-size: 1.8rem;		
	}	
	@media (min-width: 993px) {
		font-size: 2rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;		
	}
	@media (min-width: 1201px) {
		padding-left: 9.6rem;
		padding-right: 9.6rem;
	}
}


// 6. Share
// ==========================================================================

.blog-d-share {
	display: flex;
	align-items: center;
}
.blog-share-title {
	display: inline-block;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: (70/1000) * 1rem;
	margin-right: 1rem;
}
.blog-share-ul { 
	font-size: 2.4rem;
	margin-bottom: 0;
	li {
		margin-right: .6rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		display: -ms-flexbox;
		display: flex;
	  -ms-flex-pack: center;
	  justify-content: center;
		align-items: center;		
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		background-color: #f4f2f4;
		color: #969494;
		cursor: pointer;
	}
	@media (max-width: 992px) {
		li {
			margin-right: .6rem;
			&:last-child {
				margin-right: 0;
			}			
		}
	}
}

// 7. Aside block
// ==========================================================================

.blog-aside-c {
	display: block;
	max-width: 29.5rem;
	margin: 0 auto;
	font-weight: 500;
	font-size: 2rem;
	line-height: (24/20);
	color: #000;
}
.blog-aside-c_title {
	display: block;
	padding: 2rem;
}


// 8. Artículos relacionados
// ==========================================================================

.related-articles {
	background-color: #fafafa;
	padding-top: 4rem;
	padding-bottom: 4rem;
	@media (min-width: 601px) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	@media (min-width: 601px) {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}	
}
.related-articles_title {
	font-size: 2.2rem;
	line-height: (52/44);
	text-transform: uppercase;
	letter-spacing: (100/1000) * 1rem;
	margin-bottom: 4rem;
	@media (min-width: 601px) {
		font-size: 4.4rem;
	}
}


// 9. Newsletter
// ==========================================================================

.newsletter-c {
	background-color: #fff;
	box-shadow: 0px -1px 14px 0px rgba(0, 0, 0, 0.07);
}