// ==========================================================================
// Mosaicos Ezarri
// ==========================================================================
//
// Table of Contents:
//
//  1. Heading
//  2. Buttons
//  3. Modal
//  4. Search



// 1. Heading
// ==========================================================================
.clc_title {
	font-size: 1.6rem;
	font-weight: 700;
	letter-spacing: (50/1000) * 1rem;
	margin: 2.35rem 0;
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}	
}
.h2-prelist {
	font-size: 2rem;
	@media (min-width: 601px) {
		font-size: 3rem;
	}
	@media (min-width: 993px) {
		font-size: 3.5rem;
	}	
}


// 2. Buttons
// ==========================================================================
.clc-list {
	position: relative;
	background-color: #fff;
	padding: 5.4rem 3rem 1.1rem 3rem;
}
.clc-ul {
	margin-bottom: 4.25rem;
}
.clc-ul_li {
	margin: .75rem .5rem;	
}
.clc-ul_a { 
	border: .1rem solid #000;
	color: #000;
	&:after {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255,255, 255, 0);
		border-bottom-color: rgba(255,255, 255, 0);
		border-width: 6px;
		margin-left: -6px;
		margin-top: -11px;
		z-index: 101;
	}
	&.active {
		&:before {
			    -ms-transform: scale(1, 1);
			        transform: scale(1, 1);
		}
		&:after {
			border-bottom-color: rgba(255,255, 255, 1);
		}
	}
}



.clc-ul_title {
	padding: .6rem 2.8rem;
}
.clc-ul_new {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	background-color: $primary;
	padding: 0 5px;
}

// 3. Modal
// ==========================================================================
.modal-no {
	position: absolute;
	z-index: 100;
	left:0;
	right:0;
}

// 4. Search
// ==========================================================================
.search-content {
  margin-top: 5rem;
  margin-bottom: 5rem;
  .form-search {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    display: none;
  }
}

.colecciones-search {
  @media (min-width: 768px) {
    overflow: hidden;
    .search-content {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgba(255,255, 255, 1);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
      margin: 0 !important;
      width: 100% !important;
    }
  }
}
.btn-search-colleciones {
  display: none;
  @media (min-width: 768px) {
    display: flex;
    background-color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 22px;
    }
  }
}


.search-text {
	font-size: 2rem;
	line-height: 1.2;
	@media (min-width: 601px) {
		font-size: 2.4rem;
  }
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
.form-search {
	width: 100%;
	max-width: 380px;
	margin-bottom: 2rem;
	input {
		padding-right: 3rem !important;
		margin-bottom: 0 !important;
	}
	button {
		position: relative;
		right: 3rem;
		width: 20px;
		height: 22px;
	}
}


.cms-colecciones {
  margin-bottom: 16px;
}
.btn-go-to-landing {
  margin-bottom: 16px;
}

.mfp-coleccion {
  .mfp-counter{
    top: 12px;
  }
  .mfp-title {
    padding-right: 0;
    text-align: center;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .mfp-bottom-bar {
    margin-top: 0;
    top: auto;
    bottom: 0;
    height: 80px;
    padding-top: 5px;
  }
  .mfp-figure:after {
    bottom: 80px;
  }
  img.mfp-img {
    padding-bottom: 80px;
  }
  .mfp-iframe-scaler {
    position: relative;
    overflow: visible;
    &+a {
      margin-top: 13.5px;
    }
  }
  .mfp-content {
    text-align: center;
  }
  .mfp-title-sp{
    width: 100%;
    text-align: left;
    padding-right: 5rem;
  }
  .btn-gen {
    font-size: 13px;
    line-height: 18px;
    padding: 0.8rem 3rem;
    margin-top: 1rem;
  }
}