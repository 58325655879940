// ==========================================================================
// index.php
// ==========================================================================
//
// Table of Contents:
//
//  1. General
//  2. Slider
//  3. Mosaico
//  4. Colecciones y colores
//  5. Área profesional
//  6. Noticias



// 1. General
// ==========================================================================

.section-home {
	padding-top: 5rem;
	@media (min-width: 1400px) {
		padding-top: 10rem;
	}
}
.h3-mosaico-ezarri {
	font-weight: 500;
	font-size: 3rem;
	margin-bottom: 0;
}

.h3-home-collection {
	font-weight: 500;
	font-size: 3rem;
	margin-bottom: 0;
}


.text-collection {
	font-size: 3rem;
	line-height: (35/30);
	.icon {
		font-size: 38px;
	}
	margin-bottom: 3.5rem;
}


// 2. Slider
// ==========================================================================
.slider-home-container {
	position: relative;
	top: -7.6rem;
	& + .section-home {
		position: relative;
		top: -7.6rem;
		& + .section-home {
			padding-top: 2.4rem;
		}
	}

	@media (min-width: 1201px) {
		&:not(.scrolled) {
			top: -12rem;
			& + .section-home {
				position: relative;
				top: -12rem;
				& + .section-home {
					padding-top: -2rem;
				}
			}
		}
	}
}

.slider-home {
	letter-spacing: (5/1000) * 1rem;
	height: 80vh;
	.slick-slide {
		position: relative;
		img {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			object-fit: cover;
			object-position: center;
			font-family: 'object-fit: cover; object-position: center;';
		}
	}
	.slick-list {
		height: 100% !important;
	}
	.slick-track {
		height: 100%;
	}

	.slick-dots {
		margin: 0;
		position: absolute;
		bottom: 20px;
	}

	.slider-home_data {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 2.8rem 6rem 10vh 6rem;
		background: linear-gradient(to bottom, rgba(0,0,0,.7), rgba(255,255,255,0));
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(to top, rgba(0,0,0,.3), rgba(255,255,255,0));
			height: 20%;
			z-index: 100;
		}
		h2{
			font-size: 5rem;
			margin: 0;
			font-weight: 500;
			position: relative;
			z-index: 101;
		}
		.slider-home_btn {
			position: relative;
			z-index: 101;
			padding: .7rem 2.3rem;
			font-weight: 400;
			background: $primary;
			border-radius: 2rem;
		}
	}
	@media (max-width: 1200.99px) {
		.slider-home_data {
			padding: 6rem 3rem;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			h2 {
				margin-bottom: 3rem;
				font-size: 4rem;
				text-align: center;
			}
		}
	}
}

// 3. Mosaico
// ==========================================================================
.mosaico-ezarri {
	display: grid;
	gap: 0 4rem;
	grid-template-columns: repeat(2, calc(50% - 3rem));
	&__item {
		&:not(:last-child) {
			margin-bottom: 3rem;
		}
		&_img {
			position: relative;
			overflow: hidden;
			width: 100%;
			margin-bottom: 1.6rem;
			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px;
			}
		}
		.h3-mosaico-ezarri {font-size: 1.5rem;}
	}
	@media (min-width: 993px) {
		grid-template-columns: repeat(4, calc(25% - 3rem));
		&__item {
			&:not(:last-child) {
				margin-bottom: 5rem;
			}
			.h3-mosaico-ezarri {font-size: 3rem;}
		}
	}
}

// 4. Colecciones y colores
// ==========================================================================
.home-section-02 {
	background-color: $primary;
	.cms {
		margin-top: 2.7rem;
	}
}

.home-section-02--content {
	padding: 4rem 9.375%;
}

// 5. Área profesional
// ==========================================================================
.index-area__item {
	padding: 5rem  8%;
	@media (min-width: 993px) {
		padding: 9.5rem  8%;
	}
}

.img-colores {
	position: relative;
	display: flex;
	&:before {
		content: '';
		padding-bottom: (590/960) * 100%;
	}
}

.img-colores__item {
	width: (100 / 3) * 1%
}

.img-colores__01 { background-color: #E6FFF1 }
.img-colores__02 { background-color: #FFF0E0 }
.img-colores__03 { background-color: #FFFEE0 }

// 6. Noticias
// ==========================================================================
.noticias_home_ezarri {
	display: grid;
	gap: 0 1rem;
	grid-template-columns: repeat(2, calc(50% - .5rem));
	@media(min-width: 993px) {
		gap: 0 4rem;
		grid-template-columns: repeat(4, calc(25% - 3rem));
	}
	&__item{
		margin-bottom: 4rem;
		&_img {
			margin-bottom: 4.4rem;
		}
		h3 {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
			@media(min-width: 993px) {
				font-size: 3.5rem;
				margin-bottom: 5rem;
			}
		}
	}
}

.index-to-blog {
	position: relative;
	&:after {
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		height: 2px;
		background: #000000;
		content: "";
		z-index: 1;
	}
	a {
		font-weight: 400;
		padding-left: 5rem;
		background: #FFFFFF;
		position: relative;
		z-index: 2;
	}
}