// ==========================================================================
// Certificaciones
// ==========================================================================
//
// Table of Contents:
//
//  1. General



// 1. General
// ==========================================================================
.cert {
	margin-bottom: 6rem;
	.cert-link-container{
		margin-left: -1rem;
		margin-right: -1rem;
		.cert-link
		{
			padding-left: 1rem;
			padding-right: 1rem;
			margin-bottom: 1rem;
		}
	}
}
.cert-cms-title {
	font-size: 2rem;
	letter-spacing: (100/1000) * 1rem;
	@media (min-width: 601px) { font-size: 3.6rem; }
	@media (min-width: 993px) { font-size: 4.8rem; }
}
.cert-ul {
	img {
		padding-bottom: 1.5rem;
	}
	li {
		margin-bottom: 3rem;
	}
}
.cert-cms {
	font-size: 1.8rem;
	margin-bottom: 3rem;
}



