// ==========================================================================
// Buttons
// ==========================================================================
//
// Table of Contents:
//
//  1. Reset
//  2. General
//  3. Sidebar boton contacta con nosotros
//  4. Con icono alineado
//  5. Generador

// 1. Reset
// ==========================================================================
button {
	border-radius: 0;
	border: 0;
	&:hover {
		text-decoration: underline;
	}
}
.btn-reset {
	background: transparent;
	padding: 0;
	border: none;
}

// 2. General
// ==========================================================================

.btn-scale {
	display: inline-block;
	position: relative;
	border: none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: transform .4s cubic-bezier(0.4, 0, 0.2, 1);
		    -ms-transform: scale(0, 1);
		        transform: scale(0, 1);
		    -ms-transform-origin: right center;
		        transform-origin: right center;
		background-color: $primary;
	}
	&:hover {
		&:before {
			    -ms-transform: scale(1, 1);
			        transform: scale(1, 1);
			    -ms-transform-origin: left center;
			        transform-origin: left center;
		}
	}
	span, strong {
		color: #000;
		position: relative;
		transition: color 0.4s cubic-bezier(0.4, 0, 0.2, 1);
	}
}
.btn-scale--dark {
	&:before {
		background-color: #fff;
	}
	span { color: #fff; }
	&:hover {
		span { color: #000; }
	}
}
.btn-std {
	background-color: $primary;
	text-transform: uppercase;
	font-size: 1.4rem;
	font-weight: 500;
	letter-spacing: (150/1000) * 1rem;
	padding: 2.2rem 4rem;
}
.border-none {
	border: none;
}
.cms .btn-scale strong {
	font-weight: 500;
}

// 3. Sidebar boton contacta con nosotros
// ==========================================================================
.btn-fixed {
    position: fixed;
    z-index: 6;
    top: 0;
    bottom: 0;
    right: 4rem;
    height: 4rem;
    background-color: $primary;
    text-transform: uppercase;
    margin: auto;
    padding: 0.8rem 2.2rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -ms-transform: rotate(-90deg) translate(50%,50%);
    transform: rotate(-90deg) translate(50%,50%);
    transition: background-color .15s ease;
    @media (min-width: 993px) {
		&:not(.btn-fixed-all) {
			display: none;
		}
	}
}
.btn-sidebar-contacta {
	padding: 2rem;
	background-color: $primary;
	height: 100%;
  border-radius: 0px;
	.col{
		border: 1px solid #fff;
		padding: 2rem;
	}
	.aside-cursive{
		text-transform: initial;
	}
	.aside-sans{
		margin-bottom: 0;
	}
	@media (max-width: 1240px){
		.aside-sans{
			font-size: 2rem;
		}
	}
	@media (max-width: 992px) {
		display: none;
	}
}

// 4. Con icono alineado
// ==========================================================================
.btn-icon {
	font-size: 1.4rem;
	letter-spacing: (50/1000) * 1rem;
	padding: 1rem 2.2rem;
	span { text-align: center; }
	i {
		position: relative;
		margin-right: 1rem;
	}
	@media (min-width: 601px) {
		//padding: 2rem 4.4rem;
		padding: 1.125rem 1.75rem;
	}	
}


// 5. Generador
// ==========================================================================
.btn-gen {
	font-size: 1.4rem;
	letter-spacing: (150/1000) * 1rem;
	padding: 1.75rem 3.85rem;
	background-color: $primary;
	&.disabled {
		background-color: #fff;
		color: rgba(51,51,51,.65);
		border: .1rem solid #ccc;
		pointer-events: none;
		cursor: default;
	}
}

.btn-primary { background-color: $primary }
.btn-white { background-color: #fff }

.btn-rounded {
	border: 1px solid #000;
	color: #000;
	background: transparent;
	border-radius: 2.2rem;
	font-size: 1.5rem;
	line-height: 1.5rem;
	padding: .6rem 2rem;
	&:hover {
		text-decoration: none;
		box-shadow: inset 0 0 3px rgba(0,0,0,.4);
	}
	&[disabled] {
		pointer-events: none;
		color: rgba(0,0,0,.5);
		border-color: rgba(0,0,0,.5);
	}
}