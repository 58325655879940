// ==========================================================================
// Case studies detail
// ==========================================================================
//
// Table of Contents:
//
//  1. Fondo




// 1. Fondo
// ==========================================================================

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //z-index: 1042;
  z-index: 9999;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}


// 2. Wrapper
// ==========================================================================

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //z-index: 1043;
  z-index: 9999;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}


// 3. Container
// ==========================================================================

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  //z-index: 1045;
  z-index: 9999;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  //z-index: 1044;
  z-index: 9999;
   }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }


button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  //z-index: 1046;
  z-index: 9999;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  text-decoration: none !important;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  cursor: default;
  overflow: visible;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  outline: none;
  padding: 0;  
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  color: #FFF;
  width: 4rem;
  height: 4rem;
  line-height: 1;
  opacity: 0.65;
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Material Icons';
  font-size: 2.4rem;
  text-decoration: none;
  text-align: center;  
  &:hover, &:focus { opacity: 1; }
  &:after {
    content: '\e5cd';
    cursor: pointer;
  }
}

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  text-align: right;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  font-family: 'Material Icons';
  font-size: 4rem;
  height: 4.8rem;
  margin: 0;
  margin-top: -2.4rem;
  opacity: 0.65;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 4.8rem;
  &:hover, &:focus { opacity: 1; }
  &:before { opacity: 0.7; }  
}



.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:before {
    content: '\e408';
  }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:before {
    content: '\e409';
  }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -ms-transform: scale(0.75);
        transform: scale(0.75); }
  .mfp-arrow-left {
    -ms-transform-origin: 0;
        transform-origin: 0; }
  .mfp-arrow-right {
    -ms-transform-origin: 100%;
        transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }






/* overlay at start */
.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}    
/*
.mfp-ezarri img.mfp-img {
  padding-bottom: 50px;
}
*/
.mfp-data {
  padding-top: 5px;
  padding-bottom: 15px;

}
.mfp-first {
  text-transform: uppercase;
  padding-bottom: 4px;
}
