.nav-default{
  ul {
    li {
      &:not(:last-of-type) {
        margin-right: 2.5rem;
      }
      a {
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-weight: 400;
      }
      h1, span {
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 0;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
  &.nav-downloads {
    @media(min-width: 993px) {
      padding: 7rem 0;
    }
  }

  &.nav-instalaciones {
    padding: 2.7rem 0 6rem 0;
  }
}

#nav-mosaic {
  margin-bottom: 3.5rem;
  ul {
    margin-bottom: 0;
    li {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 0;
      float: none;
      ul {
        margin-top: 1.6rem;
        li {
          margin-right: 5rem;
          &:last-of-type {
            margin-right: 7rem;
          }
          a {
            font-size: 1.5rem;
            font-weight: 400;
            &.active {
              font-weight: 500;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &-mobile{
    margin-bottom: 2.5rem;
    button {
      width: 100%;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      text-align: left;
      padding: 1.2rem 1rem;
      font-size: 2rem;
      &:hover {
        box-shadow: none;
      }
      .arrow {
        position: absolute;
        right: 1rem;
        top: calc(-13px + 50%);
        width: 15px;
        height: 15px;
        overflow: hidden;
        transform: rotate(45deg);
        border: solid black;
        border-width: 0 2px 2px 0;

      }
    }
    .submenu {
      position: absolute;
      top: 4.1rem;
      left: 0;
      right: 0;
      background: #FFF;
      padding: 1.4rem 1.6rem;
      width: auto;
      z-index: 100;
      display: none;
      .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          flex: 0 0 100%;
          max-width: 100%;
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
          &.category{
            font-size: 1.2rem;
            text-decoration: underline;
          }
          &:not(.category) {
            padding-left: 1rem;
          }
          a {
            font-weight: 400;
            &.active {
              font-weight: 500;
              font-size: 1.6rem;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.header-downloads {
  @media(max-width: 992.99px) {
    padding-bottom: .9rem;
    border-bottom: 2px solid #000;
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h1 {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 0;
      cursor: pointer;
      .material-icons {
        position: relative;
        top: 7px;
      }
    }
    #dropdown-downloads {
      min-width: 200px;
      a {
        line-height: 1.2 !important;
      }
    }
  }
}

#nav-gallery {
  @media (max-width: 992.99px) {
    ul {
      justify-content: space-between;
      align-items: end;
      padding-bottom: .9rem;
      border-bottom: 2px solid #000;
      li {
        margin-right: 0;
        h1, span {
          font-size: 4rem;
          line-height: 4rem;
          text-decoration: none;
        }
        a{
          font-size: 1.3rem;
          line-height: 1.3rem;
          font-weight: 400;
        }
      }
    }
  }
}

.nav-instalaciones-mobile {
  margin: 2rem 0;
  position: relative;
  button {
    width: 100%;
  }
  .navigation {
    position: absolute;
    display: none;
    top: -5px;
    left: 0;
    right: 0;
    border-radius: 1.8rem;
    background: $primary;
    padding: 1.4rem 1.6rem;
    .close {
      position: absolute;
      right: 1.6rem;
      top: 1rem;
      cursor: pointer;
    }
    ul {
      li{
        margin-bottom: 1.5rem;
        span {
          font-weight: 500;
          text-decoration: underline;
        }
        a {
          font-weight: 400;
        }
      }
    }
  }
}