// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@each $font in glyphicons-halflings-regular, font-awesome {
  @include font-face-opt($font, $font);
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('../fonts/AkzidenzGroteskPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;  
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('../fonts/AkzidenzGroteskPro-Md.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;  
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('../fonts/AkzidenzGroteskPro-Md.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;  
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('../fonts/AkzidenzGroteskPro-Md.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;  
}
