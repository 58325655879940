@import '../../../abstracts/variables';

.collapsible {
  border-top: 1px solid $collapsible-border-color;
  border-right: 1px solid $collapsible-border-color;
  border-left: 1px solid $collapsible-border-color;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  @extend .z-depth-1;
}

.collapsible-header {
  /* cursor: pointer; */
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  padding: 1rem;
  background-color: $collapsible-header-color;
  border-bottom: 1px solid $collapsible-border-color;
  h5{
    color: $primary;
    color: #07588e;
  }
  i {
    width: 2rem;
    font-size: 1.6rem;
    display: inline-block;
    text-align: center;
    margin-right: 1rem;
  }
  @media (min-width: 992px) {
    display: flex;
  }
  @media (max-width: 991px) {
   h5{
    margin-top: 0.5rem;
   }
  }
}

.collapsible-body {
  display: none;
  border-bottom: 1px solid $collapsible-border-color;
  box-sizing: border-box;
  padding: 2rem;
}

// Popout Collapsible

.collapsible.popout {
  border: none;
  box-shadow: none;
  > li {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    // transform: scaleX(.92);
    margin: 0 24px;
    transition: margin .35s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  > li.active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    margin: 16px 0;
    // transform: scaleX(1);
  }
}
