.cs-c {
	img { width: 100%; } // por ahora
}
.cs-c_content {
	font-weight: 500;
	letter-spacing: (100/1000) * 1rem;
	line-height: 1;
	text-transform: uppercase;
	padding: 1.2rem 1.2rem 2rem;
	@media (min-width: 601px) {
		padding: 2.4rem 2.4rem 3.9rem;
	}
}
.cs-c_title {
	font-size: 1.8rem;
	margin-bottom: .5rem;
	@media (min-width: 601px) {
		font-size: 2.4rem;
	}
}
.cs-c_country {
	color: #999;
	margin-top: .4rem;
	@media (min-width: 601px) {
		font-size: 2rem;
	}	
}
.mfp {
	figure {
		cursor: pointer;
		img, .item-c_hover {
			border-radius: .8rem;
		}
	}
	ul {
		display: none;
	}
}