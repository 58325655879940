// ==========================================================================
// Colecciones
// ==========================================================================
//
// Table of Contents:
//
//  1. General



// 1. General
// ==========================================================================

.row--serie {
  @media (max-width: 992px) {
    > .col {
      &:not(:last-child) {
        margin-bottom: 40px;
        border-bottom: 1px solid #e5e4e5;
      }
    }
  }
  @media (min-width: 993px) {
    > .col {
      &:not(:last-child) {
        border-right: 1px solid #e5e4e5;
      }
    }
  }
}
.serie-title {
  font-weight: 500;
  @media (min-width: 601px) {
    font-size: 3.2rem;
  }
}
.colecciones{
  .premium{
        border-bottom: .4rem solid #006464;
  }
  .classic{
        border-bottom: .4rem solid #51a025;
  }
  .custom{
        border-bottom: .4rem solid #bcbd00;
  }
  .row--serie {
    h2{
      position: relative;
      &:after {
        content: '';
        position: relative;
        padding-top: 1rem;
        display: block;
        width: 4.5rem;
        margin: 0 auto;
      }
      a{
        color: #000;
        &:hover{
          transition: color .2s cubic-bezier(.25,.46,.45,.94);
        }
      }
    }
  }
  .list--serie{
    span{
        padding: 1.5rem 3rem;
    }
    li:nth-child(1n){
        .btn-scale:before{
            background-color: #006464;
        }
    }
    li:nth-child(2n){
        .btn-scale:before{
            background-color: #51a025;
        }
    }
    li:nth-child(3n){
        .btn-scale:before{
            background-color: #bcbd00;
        }
    }
  }
}


