.block {
  + .block {
     margin-top: 5rem;
    &.block-adjacent {
      margin-top: 3rem;
    }
  }
  .cms-area {
    margin-bottom: 0;
  }
  .video-container {
    width: 100%;
    .block-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .video-btn {
        background-color: transparent;
        position: relative;
        top: unset;
        left: unset;
        width: auto;
        height: auto;
        border-radius: 0;
        color: #FFFFFF;
        transform: none;
        display: block;
        &:after {
          display: none;
        }
        span {
          width: 0;
          height: 0;
          border-top: 3.3rem solid transparent;
          border-bottom: 3.3rem solid transparent;
          border-left: 6.6rem solid #FFFFFF;
          display: inline-block;
        }
      }
    }
    &.active {
      .block-play {
        display: none;
      }
    }
    &.video-full-width {
      padding-bottom: calc(9*100%/21);
    }
    @media(max-width: 600px) {
      padding-bottom: 100%;
    }
  }
  &.block-type-3 {
    + .block {
      margin-top: 3rem;
    }
    &.block-adjacent {
      margin-top: 1rem;
    }
  }
  .cs-mosaic {
    padding-left: 0;
    margin-left: -1rem;
    margin-right: -1rem;
    li:last-child {
      margin-bottom: 2rem;
    }
    li {
      @media(max-width: 450px) {
        max-width: 33%;
      }
    }
  }
  &.block-type-4{
    .block-text {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.5;
      padding-left: 4rem;
      padding-right: 4rem;
      @media(max-width: 600px) {
        font-size: 2rem;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &.block-type-5 {
    .slick-dots {
      button {
       &::before {
          border-color: #000;
       }
      }
      .slick-active {
        button {
          &::before {
            background: #000;
          }
        }
      }
    }
    .slick-slide-side {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &.block-type-6 {
    .highlight {
      padding: 4rem;
      border: .1rem solid #eaf6fe;
      box-shadow: 0 0 1rem rgba(0,0,0,0.1);
      &.more {
        background: #eaf6fe;
        .btn-std {
          border: .1rem solid #000;
        }
      }
    }
  }
}