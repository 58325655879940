.wrapper {
  @media (min-width: 1201px) {
    &.in-tactil {
      padding-top: 2rem;
      overflow: hidden;
      height: 100vh;
      .iframe-generator {
        min-height: 1px;
      }
    }
  }
}

.pt-logo {
  margin: 0 1%;
  width: 23%;
  float: left;
  .logo {
    width: 100%;
    height: auto;
  }
}

.container-pt-title {
  max-width: 1600px;
  margin: 0 auto;
  .pt-title {
    float: right;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 4rem;
    line-height: 1;
    font-weight: bold;
  }
}

#tactil-screensaver {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &.hidden {
    display: none;
  }
  .slick-track {
    width: calc(12 * 1920px) !important;
  }
  .slick-slide {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}