input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-moz-focus-outer {
  border: 0;
}
input[type=range]:-moz-focus-outer {
  border: 0;
}

// X. BAR
// ==========================================================================
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #e5e4e5;
  border-radius: 2.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $primary;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #e5e4e5;
  border-radius: 2.5px;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 2.5px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $primary;
  border-radius: 2.5px;
}
input[type=range]::-ms-fill-upper {
  background: #e5e4e5;
  border-radius: 2.5px;
}
input[type=range]:focus::-ms-fill-lower {
  background: $primary;
}
input[type=range]:focus::-ms-fill-upper {
  background: $primary;
}




// X. THUMB
// ==========================================================================
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 2px 4px rgba(0,0,0,.17);
  border: 10px solid #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 2px 4px rgba(0,0,0,.17);
  border: 10px solid #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  box-sizing: border-box;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 2px 4px rgba(0,0,0,.17);
  border: 5px solid #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 0;
}