.aside {
	@media (max-width: 992px) {
		margin-top: 4rem;
	}
}
.aside-cursive {
	display: block;
	font-size: 1.5rem;
	margin-bottom: .8rem;
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}	
}
.aside-sans {
	display: block;
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
	@media (min-width: 601px) {
		font-size: 2.4rem;
		margin-bottom: 3rem;
	}
}