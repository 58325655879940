.title-404 {
	padding-top: 5rem;
	font-size: 7.5rem;
	letter-spacing: 1rem;
	@media (min-width: 601px) {
		font-size: 15rem;
	}
}
.text-404 {
	padding-bottom: 2.5rem;
	@media (min-width: 601px) {
		padding-bottom: 5rem;
		font-size: 2.4rem;
	}
}