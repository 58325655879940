// ==========================================================================
// Área Técnica - Materiales
// ==========================================================================
//
// Table of Contents:
//
//  1. Introducción
//  2. Tablas
//  3. Calculadora



// 1. Introducción
// ==========================================================================
.materiales-intro {
	font-size: 1.8rem;
	line-height: (24/20);
	letter-spacing: (20/1000) * 1rem;
	margin-top: 2rem;
	margin-bottom: 5rem;
	@media (min-width: 601px) {
		font-size: 2rem;
		margin-top: 4rem;
		margin-bottom: 10rem;
	}
}

// 2. Tablas
// ==========================================================================
.table-collapsed {
	thead {
		th {
			position: relative;
			cursor: pointer;
			&:after {
				position: absolute;
				right: 2rem;
				content: "";
				top: calc(50% - 3px);
				transform: translateY(-50%) rotate(225deg);
				width: 15px;
				height: 15px;
				border-top: 2px solid #000;
				border-left: 2px solid #000;
			}
		}
	}
	tbody {
		display: none;
	}
	&.active {
		thead {
			th {
				&:after {
					transform: translateY(-50%) rotate(45deg);
					top: calc(50% + 6px);
				}
			}
		}
		tbody {
			display: block;
		}
	}
}

// 3. Calculadora
// ==========================================================================
.wrapper--mosaic_block {
	.body {
		#form-calculator, #form-tools {
			margin-top: 2rem;
			[type="number"] {
				border-bottom: .1rem solid #000;
				margin-bottom: 2.6rem;
				height: 3.8rem;
				font-size: 1.5rem;
				&[readonly] {
					border-color: rgba(0,0,0,.5);
					cursor: not-allowed;
				}
				&.with-help {
					margin-bottom: .6rem;
				}
			}
			.help-block:not(.invalid-feedback) {
				font-size: 1.2rem;
				margin-bottom: 2rem;
			}
			.help-block:not(.invalid-feedback) + .help-block.invalid-feedback {
				margin-bottom: -2rem;
			}
			.select-field {
				label {
					position: absolute;
					font-size: 12px;
					top: -23px;
					left: 0;
					&:not(.activate) {
						display: none;
					}
				}
			}
			.results {
				> div {
					margin-bottom: 1rem;
				}
				label, span, input {
					font-size: 1.8rem;
					font-weight: bold;
				}
				input {
					border: 0;
					margin: 0;
					height: auto;
					text-align: right;
					padding-right: .3rem;
					width: 70px;
					cursor: default !important;
				}
				label {
					margin-bottom: 0;
					padding-bottom: 0;
					width: 85px;
				}
			}
			.col.pl-0 {
				padding-left: 0 !important;
			}
		}
		#form-tools {
			.col {
				&.d-block {
					float: none;
					margin-bottom: .5rem;
					+ .header_title {
						margin-top: 3rem;
					}
					input, span {
						font-size: 15px;
					}
					input {
						border: 0;
						margin: 0;
						height: auto;
						text-align: right;
						padding-right: .3rem;
						width: 50px;
						cursor: default !important;
					}
					label {
						margin-bottom: 0;
						padding-bottom: 0;
						width: 150px;
					}
				}
			}
		}
	}
	&.block-budget {
		display: none;
	}
}