@charset "UTF-8";

@import '../../abstracts/variables';

// Default Variables
$slick-loader-path: "./media/images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0;

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff url("../images/ajax-loader.gif") center center no-repeat;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		font-family: 'Material Icons';
		font-size: 40px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	left: 0;
	z-index: 1;
	&:before {
		content: '\e5cb';
	}
}

.slick-next {
	right: 0;
	&:before {
		content: '\e5cc';
	}
}

.slick-dots {
	position: relative;
	list-style: none;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	width: 100%;
	margin-top: 3rem;
	overflow: hidden;
	li {
		position: relative;
		display: inline-block;
		padding: 0;
		cursor: pointer;
		&:not(:last-child) {
			margin-right: 1.1rem;
		}
		&.slick-active button {
			&:before {
				background-color: $primary;                
			}            
		}
	}
	button {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 10px;
		height: 10px;
		background-color: transparent;
		border: none;
		outline: none;
		line-height: 0;
		font-size: 0;
		color: transparent;
		cursor: pointer;            
		&:before {
			content: '';
			position: absolute;
			display: block;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			border: .1rem solid $primary;
		}                      
		&:hover, &:focus {
			outline: none;
		}
	}
}