// ==========================================================================
// Jointpoint
// ==========================================================================
//
// Table of Contents:
//
//  1. Headings
//  2. Block


.wrapper-joinpoint {

	// 1. Headings
	// ==========================================================================
	.jointpoint-header {
		position: relative;
		overflow: hidden;
		margin-bottom: 5rem;
		@media (min-width: 993px) {
			margin-bottom: 10rem;
		}
		&:before {
			content: "";
			display: block;
			padding-top: 47.03%;
		}
		&.jointpoint-header__video {
			&:before {
				display: none;
			}
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
		.text {
			position: absolute;
			width: 60%;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			color: white;
			font-weight: 500;
			h1 {
				font-size: 5rem;
				@media (min-width: 993px) {
					font-size: 20rem;
				}
			}
			h2 {
				font-size: 2.5rem;
				@media (min-width: 993px) {
					font-size: 10rem;
				}
			}
		}
		.video-container {
			.info {
				min-width: 350px;
				.video-btn {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}
		}
	}


	// 2. Block
	// ==========================================================================
	.block {
		margin-bottom: 5rem;
		&.last {
			margin-bottom: 0;
		}
		p:last-of-type{
			margin-bottom: 0;
		}
		@media (min-width: 993px) {
			margin-bottom: 10rem;
			&.last {
				margin-bottom: 3rem;
			}
		}
	}
}


