// ==========================================================================
// Case studies detail
// ==========================================================================
//
// Table of Contents:
//
//  1. General



// 1. General
// ==========================================================================
.cs-d-h1 {
	margin-bottom: 1rem;
	letter-spacing: (100/1000) * 1rem;
	@media (min-width: 601px) {
		margin-bottom: 2rem;
	}
	@media (min-width: 601px) {
		margin-bottom: 4rem;
	}	
}
.cs-d-country {
	display: block;
	color: #999;
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: (100/1000) * 1rem;
	text-transform: uppercase;
	margin-bottom: 1.6rem;
	@media (min-width: 601px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) {
		font-size: 2.4rem;
	}	
}
.cs-d-author {
	color: #333;
	font-size: 1.6rem;
	letter-spacing: (100/1000) * 1rem;
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}	
}


// 2. CMS
// ==========================================================================
.cs-cms-intro {
	font-size: 1.8rem;
	line-height: (35/20);
	@include no-last-el-margin;
	@media (min-width: 601px) {
		font-size: 2rem;
	}	
}
.cs-cms-title {
	font-size: 2rem;
	letter-spacing: (100/1000) * 1rem;
	margin-top: 4rem;
	margin-bottom: 2.5rem;
	text-transform: uppercase;
	@media (min-width: 601px) {
		font-size: 2.4rem;
	}
	@media (min-width: 993px) {
		font-size: 3.4rem;
	}		
}
.cs-ul {
	.cs-li {
		&:first-child { padding-top: 0; }
	}
}
.cs-li {
	border-bottom: .1rem solid #000;
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.cs-cms-content {
	@include no-last-el-margin;
	li { margin-bottom: 2rem; }
	blockquote {
		strong { font-weight: 500; }
		span {
			display: block;
			letter-spacing: (20/1000) * 1rem;
			margin-top: 1rem;
			padding-left: 25px;
			position: relative;
			&:before {
				content: '\2014';
				position: absolute;
				left: 0;
			}
		}
	}
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}	
}


// 3. Mosaicos
// ==========================================================================
.cs-mosaic { 
	margin-top: 2rem;
	margin-bottom: 0;
	padding-left: .5rem;
	padding-right: .5rem;
	li {
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	img {
		margin: 0 auto;
	}
	span {
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		    -ms-flex: 1;
		        flex: 1;
		    -ms-flex-pack: center;
		        justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		font-weight: 500;
		text-transform: uppercase;
		padding: .6rem 0 1.2rem;
	}
}
.cs-mosaic_item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	-moz-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
	padding-top: 1.5rem;
	width: 11rem;
}