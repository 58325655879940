// ==========================================================================
// Items
// ==========================================================================
//
// Table of Contents:
//
//  1. General
//  2. Específicos de proyectos
//  3. Específicos de mosaicos



// 1. General
// ==========================================================================

.item-c {
	color: #000;
	transition: all 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
	figure {
		position: relative;
		.new {
			position: absolute;
			z-index: 201;
			left: 2.2rem;
			top: 2.2rem;
			padding: .3rem .5rem;
			background: #FFF;
		}
	}
	&:hover {
		.item-c_hover {
			opacity: 1;
		}
		.item_c_line-t, .item_c_line-b {
			width: 100%;
		}
		.item_c_line-r, .item_c_line-l {
			height: 100%;
		}		
	}
}
.item-c_hover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 102, 95, .5);
	opacity: 0;
	transition: all .4s cubic-bezier(.4,0,.2,1);
}
.item_c_lines {
	position: absolute;
	top: 2rem;
	right: 2rem;
	bottom: 2rem;
	left: 2rem;	
}
.item_c_line-t, .item_c_line-r, .item_c_line-b, .item_c_line-l {
	position: absolute;
	background-color: #fff;
	transition: all .4s cubic-bezier(.4,0,.2,1);
	transition-delay: 100ms;
}
.item_c_line-t, .item_c_line-b {
	width: 0;
	height: .2rem;
}
.item_c_line-r, .item_c_line-l {
	width: .2rem;
	height: 0;
}
.item_c_line-t {
	top: 0;
	left: 0;	
}
.item_c_line-r {
	top: 0;
	right: 0;
}
.item_c_line-b {
	right: 0;
	bottom: 0;
}
.item_c_line-l {
	bottom: 0;
	left: 0;
}

// 2. Específicos de proyectos
// ==========================================================================
.projects-container
{
	position: relative;
	.loader-container{
		display: none;
	}
	&.loading
	{
		min-height: 250px;
		.loader-container{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: #FFF;
			z-index: 10;
			.preloader-container{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
}

// 3. Específicos de mosaicos
// ==========================================================================
.mosaic-list {
	.item-c_hover {
		z-index: 200;
	}
}