nav {
  &.nav-extended {
    height: auto;

    .nav-wrapper {
      min-height: $navbar-height-mobile;
      height: auto;
    }

    .nav-content {
      position: relative;
      line-height: normal;
    }
  }

  width: 100%;
  i,
  [class^="mdi-"], [class*="mdi-"],
  i.material-icons {
    
  }

  .nav-wrapper {
    position: relative;
    height: 100%;
    background-color: #fff; /* AND */
  }
  @media (min-width: 1201px) {
    a.button-collapse { display: none; }
  }

  // Collapse button
  .button-collapse {
    position: absolute;
    z-index: 1;
    right: 3rem;
    @media (min-width: 1201px) {
      right: 1.5rem;
    }
    span {
      display: block;
      width: 53px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #000;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  // Logo
  .brand-logo {
    display: block;
    font-size: 0;
    &.center {
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    @media (max-width: 1200px) {
      position: absolute;
      color: $navbar-font-color;
      padding: 0;      
      left: 3rem;
      top: 50%;
      transform: translateY(-50%);
    }
    &.right {
      right: 0.5rem;
      padding: 0;
    }
    i,
    [class^="mdi-"], [class*="mdi-"],
    i.material-icons {
      float: left;
      margin-right: 15px;
    }
  }


  // Title
  .nav-title {
    display: inline-block;
    font-size: 32px;
    padding: 28px 0;
  }


  // Navbar Links
  ul {
    margin: 0;

    li {
      float: left;
      padding: 0;
    }
    a {
      cursor: pointer;
      &.btn, &.btn-large, &.btn-flat, &.btn-floating {
        margin-top: -2px;
        margin-left: 15px;
        margin-right: 15px;

        & > .material-icons {
          height: inherit;
          line-height: inherit;
        }
      }
    }

    &.left {
      float: left;
    }
  }

  // Navbar Search Form
  form {
    height: 100%;
  }

  .input-field {
    margin: 0;
    height: 100%;

    input {
      height: 100%;
      font-size: 1.2rem;
      border: none;
      padding-left: 2rem;

      &:focus, &[type=text]:valid, &[type=password]:valid,
      &[type=email]:valid, &[type=url]:valid, &[type=date]:valid {
        border: none;
        box-shadow: none;
      }
    }

    label {
      top: 0;
      left: 0;

      i {
        color: rgba(255,255,255,.7);
        transition: color .3s;
      }
      &.active i { color: $navbar-font-color; }
    }
  }
  @media (max-width: 1200px) {
/*     ul li a.active {
      background-color: rgba(0,0,0,.1);
    } */
  }
}

// Fixed Navbar
.navbar-fixed {
  position: relative;
  height: $navbar-height-mobile;
  z-index: 997;

  nav {
    position: fixed;
  }
}
@media #{$medium-and-up} {
  nav.nav-extended .nav-wrapper {
    min-height: $navbar-height;
  }
  .navbar-fixed {
    height: $navbar-height;
  }
}
