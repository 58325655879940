.side-nav {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0;
  transform: translateX(-100%);
  height: calc(100% + 60px);
  height: -moz-calc(100%); //Temporary Firefox Fix
  padding-bottom: 60px;
  background-color: $sidenav-bg-color;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;

  @extend .z-depth-1;

  // Right Align
  &.right-aligned {
    right: 0;
    transform: translateX(105%);
    left: auto;
  }

  > li {
    width: calc(-6rem + 100%);
    padding: 1.2rem 0;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 2rem;
    &.header-mobile-menu {
      height: 7.5rem;
      margin-bottom: 6rem;
      .close {
        font-size: 2.8rem;
        font-weight: 400;
      }
    }
    &.has-collapsible {
      border-bottom: 1px solid #000;
      &.first {
        border-top: 1px solid #000;
      }
      .collapsible-header {
        padding: 0;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        position: relative;
        font-size: 2rem;
        .accordion-icon {
          display: block;
          width: 15px;
          height: 15px;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          transform: rotate(225deg);
          position: absolute;
          right: 1.5rem;
          top: 0;
          &:before, &:after {
            display: none;
          }
        }
      }
      .collapsible-body {
        border: 0;
        padding: 0;
        ul {
          margin: 2rem 0 0 0;
          display: flex;
          flex-wrap: wrap;
          &.first-filters {
            margin-top: 0;
          }
          li {
            flex: 0 0 100%;
            margin-bottom: 2rem;
            font-size: 1.5rem;
            .title {
              font-weight: 500;
            }
            ul {
              margin: 2.5rem 0 0 6rem;
              li {
                a {
                  font-weight: 400;
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          &.filters {
            > li {
              flex: 0 0 calc(50% - 1rem);;
              max-width: calc(50% - 1rem);
              margin-left: .5rem;
              & + li:not(.full) {
                margin-right: .5rem;
              }
              &.full {
                flex: 0 0 100%;
                max-width: 100%;
                ul {
                  li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-left: 0;
                  }
                }
              }
              .title {
                display: block;
                width: 100%;
                padding-bottom: .8rem;
                border-bottom: 1px solid #A0A0A0;
                margin-bottom: 1.2rem;
              }
              ul {
                margin: 0 0 2.5rem 0;
                &.colors {
                  li {
                    flex: 0 0 auto;
                    margin-bottom: 1.4rem;
                    margin-right: 1.4rem;
                  }
                }
              }
              a {
                &.safe-step {
                  font-weight: 500;
                }
              }
              &.advanced-search {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                justify-content: end;
                button {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      & + li.mobile-li {
        margin-top: 4.5rem;
      }
    }
    &.mobile-li {
      padding: 0;
      margin-bottom: 2.5rem;
    }
    &.mobile-submenu {
      margin-top: .5rem;
      margin-left: 0;
      margin-right: 0;
      padding: 2.5rem 3rem;
      width: 100%;
      background: $primary;
      font-size: 1.5rem;
      .item {
        width: 100%;
        display: block;
        + .item {
          margin-top: 3rem;
        }
        .title {
          margin-bottom: 1.8rem;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            float: none;
          }
          &.languages {
            justify-content: space-between;
            li {
              a {
                font-weight: 400;
                &.active {
                  font-weight: 500;
                  cursor: default;
                  pointer-events: none;
                }
              }
            }
          }
          &.socials {
            li {
              flex: 0 0 50%;
              max-width: 50%;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }
}
