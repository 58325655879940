#cookies-popup {
	align-items: center;
	background-color: rgba(48,48,48,.55);
	border-top: 1px solid lighten($color: #303030, $amount: 5%);
	bottom: 0;
	display: flex;
	font-size: 13px;
	justify-content: center;
	left: 0;
	padding: 15px 0 20px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9100;
	.actions{
		display: flex;
		justify-content: space-between;
	}
}
#cookie-btn {
	border: 1px solid #000;
	color: #000;
	background: 0 0;
	border-radius: 2.2rem;
	font-size: 1.5rem;
	line-height: 1.5rem;
	padding: .6rem 2rem;
	&:hover, &:focus, &:active{
		text-decoration: none;
		box-shadow: inset 0 0 3px rgba(0,0,0,.4);
	}
}
.cookies, #modal-cookies-popup{
	.location{
		width: 90%;
		max-width: 900px;
		margin: 4rem auto;
		padding: 3rem;
		ul{
			padding: 0;
		}
		li{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 0;
			margin: 0;
			border-bottom: 1px solid rgba(0,0,0,.7);
			&:before{
				display: none;
			}
			/* &:not(:last-child){
                border-bottom: 2px solid #f1f6fa;
            } */
			.checkbox-c {
				margin-bottom: 0;
			}
			.cookie-type{
				margin: 0 2rem 0 0;
			}
		}
		.box{
			border: 1px solid rgba(107,124,147,.9);
		}

		#configure-cookie-finish{
			border: 1px solid #000;
			color: #000;
			background: 0 0;
			border-radius: 2.2rem;
			font-size: 1.5rem;
			line-height: 1.5rem;
			padding: .6rem 2rem;
			&:hover, &:focus, &:active{
				text-decoration: none;
				box-shadow: inset 0 0 3px rgba(0,0,0,.4);
			}
		}
	}
}

.legal-cookies {
	.location {
		box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
		background: #FFF;
	}
}

#modal-cookies-popup {
	position: fixed;
	z-index: 9200;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,.75);
	display: none;
	.close{
		position: absolute;
		top: 10px;
		right: 30px;
		font-size: 60px;
		color: white;
		text-shadow: none;
		opacity: .75;
		background: none;
		&:hover
		{
			opacity: .45;
			text-decoration: none;
		}
	}
	.location{
		width: 90%;
		max-width: 900px;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background: $primary;
		box-shadow: none;
	}
}

.cookies-card {
	background-color: $primary;
	padding: 3.2rem;
}

.video-no-cookies {
	position: relative;
	display: block;
	height: 0;
	padding: 0 0 56.25% 0;
	overflow: hidden;
	background: black;
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90%;
		transform: translate(-50%,-50%);
		color: white;
		text-align: center;
		a {
			color: white;
		}
	}
}