.cookie-no {
  .newsletter-bar {
    display: none;
  }
  @media (min-width: 760px) {
    .newsletter-bar {
      display: block;
      padding-bottom: 91px;
    }
  }
  @media (min-width: 1274px) {
    .newsletter-bar {
      padding-bottom: 70px;
    }
  }
}

.newsletter-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 -3px 10px 0 rgba(0,0,0,.0785);
  padding: 25px 0;
  border-bottom: 1px solid rgba(0,0,0,.05);
  transform: translateY(120%);
  transition: transform .3s, opacity .3s;
  &.show {
    transform: translateY(0);
  }
  .col {
    width: 100%;
  }
  form {
    max-width: 60rem;
    margin: 1.5rem auto 0 auto;
  }
  @media (min-width: 992px) {
    form {
      margin: 0;
    }
    .col {
      width: auto;
    }    
  }
}
.newsletter-bar__title {
  font-size: 2rem;
  font-weight: 500;
  span {
    display: block;
    &:first-child {
      font-size: 16px;
    }
  }
}
.newsletter-bar__fields {
  input {
    background-color: #fff;
    height: 38px;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0 15px 0 0;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    &:focus {
      outline: 0;
    }
  }
  button {
    height: 38px;
    background-color: #fff;
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: .05rem;
    text-transform: uppercase;
    padding: 0 28px;
    border: none;
    &:active {
      border: none;
    }
  }
  .form-error {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}
#newsletter-bar__close {
  position: absolute;
  right: 15px;
  color: rgba(0,0,0,.5);
  i {
    font-size: 1.8rem;
  }
}