// ==========================================================================
// Catalogo imágenes
// ==========================================================================
//
// Table of Contents:
//
//  1.



// 1. 
// ==========================================================================

.search-pic-filter {
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
  li {
    margin-bottom: 0.6rem;
  }
  label {
    font-size: 1.6rem;
    cursor: pointer;
    span {
      display: -ms-flexbox;
      display: flex;
      &.option{
        .filter-heart{
          width: 22px;
          height: 20px;
          margin-right: 0.4rem;
          margin-left: 0.4rem;
          background-image: url(../images/galeria/heart.svg);
        }
      }
    }
  }
  .checkbox {
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
    border: 1px solid #000;
    position: relative;
    top: .3rem;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  [type=checkbox]:checked+.checkbox {
    &:before {
      width: 5px;
      height: 10px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
          -ms-transform: rotate(37deg);
              transform: rotateZ(37deg);
          -ms-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
}

.search-pic {
  column-count: 2;
  column-gap: 1.5rem;
  @media (min-width: 993px) {
    column-count: 4;
    column-gap: 3rem;
  }
  li {
    margin-bottom: 1.2rem;
    .like{
      opacity: 0;
      @media (max-width: 767px) {
        opacity: 1;
      }
    }
    &:hover{
      .like{
        opacity: 1;
      }
    }
  }
  .like{
    position: relative;
    .d-flex{
      position: absolute;
      right: 0;
      top: -25px;
      max-width: fit-content;
      justify-content: flex-end;
      background-color: #fff; 
      padding: .2rem .5rem .2rem .5rem;
      cursor: default;
    }
    .heart{
      width: 22px!important;
      height: 21px!important;
      //margin-right: 0.5rem;
      background-image: url(../images/galeria/heart.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      &.yes{
        background-image: url(../images/galeria/heart-green.svg);
        background-size: contain;
        cursor: not-allowed;
      }
    }
    span{
      color: #000;
    }
  }
}

.filter-img {
  position: relative;
  img {
    position: relative;
    display: inline-block;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    z-index: -1;
  }
}

.modal {
  &.modal-cat-img {
    .modal-close {
      top: 16px;
      right: 16px;
    }
    .modal-content {
      padding-top: 44px;
    }
    img {
      margin: 0 auto;
    }
    .modal-footer {
      text-align: center;
    }
  } 

}

.filters {
  .filters-bread {
    margin-bottom: 2rem;
    display: none;
    .item {
      background: $primary;
      padding: .5rem 1.3rem;
      border-radius: 1.8rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      line-height: 1.5rem;
      white-space: nowrap;
      margin-bottom: 1rem;
      margin-left: 1rem;
      display: inline-block;
      i {
        cursor: pointer;
        font-style: normal;
        font-size: 2rem;
        color: rgba(0,0,0,.8);
        position: relative;
        top: 1px;
        margin-left: 1rem;
      }
      &.base {
        display: none;
      }
    }
    @media (max-width: 600.99px) {
      .item {
        font-size: 1.2rem;
        padding: .4rem .9rem;
        i {
          font-size: 1.6rem;
        }
      }
    }
  }
  form {
    .filters-item {
      padding-top: .7rem;
      padding-bottom: .7rem;
      border-top: 1px solid #000;
      h2 {
        padding: 0 1.5rem 0 3.1rem;
        font-size: 1.8rem;
        line-height: 1.8rem;
        margin-bottom: 0;
        cursor: pointer;
        .filter-heart{
          float: left;
          width: 22px;
          height: 20px;
          margin-right: 0.4rem;
          background-image: url(../images/galeria/heart.svg);
        }
        .arrow {
          width: 15px;
          height: 15px;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          transform: rotate(225deg);
          position: relative;
          top: -3px;
        }
      }
      ul {
        margin-top: 1.4rem;
        margin-bottom: 0.7rem;
        padding-left: .6rem;
        li {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }
      }
      .filters-container {
        height: 0;
        overflow: hidden;
        transition: height 0.3s;
      }
      &.showing {
        h2 {
          .arrow {
            transform: rotate(45deg);
            top: 6px;
          }
        }
        .filters-container {
          height: auto;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid #000;
      }
    }
    #filtro-coloragua{
      ul {
        padding-left: 3.1rem;
        display: grid;
        grid-template-columns: repeat(3, 5rem);
        grid-gap: 1.5rem;
        li {
          height: 5rem;
          margin-bottom: 0;
          .checkbox {
            display: none;
          }
          .option {
            position: relative;
          }
          [type=checkbox]:checked + .checkbox + .option {
            &:before {
              position: absolute;
              top: 15px;
              left: 13px;
              content: "";
              width: 10px;
              height: 15px;
              border-top: 1px solid transparent;
              border-left: 1px solid transparent;
              border-right: 1px solid #000;
              border-bottom: 1px solid #000;
              -ms-transform: rotate(37deg);
              transform: rotateZ(37deg);
              -ms-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
              z-index: 10;
            }
            &:after {
              position: absolute;
              content: "";
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(255,255,255,.3);
              z-index: 5;
            }
          }
        }
      }
    }
    #filtro-colecciones {
      h3 {
        font-size: 1.2rem;
        line-height: 2.5rem;
        margin-top: 1.9rem;
        margin-bottom: 0;
        padding: 0 0 0 3.1rem;
      }
      ul {
        margin-top: 0;
      }
      .classic {
        margin-left: 7rem;
      }
    }
  }
  @media (max-width: 600.99px) {
    form {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .filters-item {
        border: 0 !important;
        padding: 0;
        margin-bottom: .8rem;
        flex: 0 0 50%;
        max-width: 50%;
        h2 {
          border: 1px solid #000;
          color: #000;
          background: transparent;
          border-radius: 2.2rem;
          font-size: 1.5rem;
          line-height: 1.5rem;
          padding: .6rem 2rem;
          width: auto;
          display: inline-block;
          &:hover {
            text-decoration: none;
            box-shadow: inset 0 0 3px rgba(0,0,0,.4);
          }
        }
        .arrow {
          display: none;
        }
        .filters-container {
          position: absolute;
          top: -2px;
          right: 0;
          left: -2px;
          background: $primary;
          border-radius: 1.8rem;
          z-index: 400;
          display: flex;
          flex-wrap: wrap;
          .super-title {
            padding-top: 1.4rem;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 1.6rem;
            flex: 0 0 100%;
          }
          .close {
            position: absolute;
            top: 1rem;
            right: 1.4rem;
            cursor: pointer;
            font-size: 2rem;
            font-weight: 400;
          }
          ul{
            margin-top: 0;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
          }
        }
        &.showing {
          .filters-container {
            min-height: 100%;
            flex-direction: column;
          }
        }
      }
      #filtro-colecciones {
        .filters-container {
          .col {
            padding-left: .7rem;
            padding-right: .7rem;
            flex: 0 0 50%;
            &.premium {
              padding-left: 1.6rem;
            }
            &.classic {
              padding-right: 1.6rem;
              margin-left: 0;
            }
            h3 {
              margin-top: 0;
              padding: 0 0 .8rem 0;
              font-size: 1.5rem;
              line-height: 1.5rem;
              margin-bottom: 1.2rem;
              border-bottom: 1px solid #A0A0A0;
            }
            ul {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
      #filtro-colormosaico {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            flex: 0 0 50%;
          }
        }
      }
    }
  }
}

.btn-filter-apply-c {
  position: sticky;
  bottom: 0;
  .button-container {
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.filter-result {
  margin-top: 3.5rem;
  position: relative;
  @media (min-width: 601px) {
    margin-top: 0;
    padding-left: 3rem;
  }
  @media (min-width: 993px) {
    padding-left: 18rem;
  }
  .loading {
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: 100%;
    height: 100%;
    z-index: 500;
    min-height: 200px;
    display: none;
    @media (min-width: 601px) {
      left: 2rem;
      width: calc(-4rem + 100%);
    }
    @media (min-width: 993px) {
      left: 17rem;
      width: calc(-19rem + 100%);
    }
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      > span {
        font-size: 10px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        animation: mulShdSpin 1.1s infinite ease;
        transform: translateZ(0);
        display: block;
      }
    }
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.5), -1.8em -1.8em 0 0em rgba(0,0,0, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.5), 1.8em -1.8em 0 0em rgba(0,0,0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.5), 2.5em 0em 0 0em rgba(0,0,0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.5), 1.75em 1.75em 0 0em rgba(0,0,0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.5), 0em 2.5em 0 0em rgba(0,0,0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.5), -1.8em 1.8em 0 0em rgba(0,0,0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.5), -2.6em 0em 0 0em rgba(0,0,0, 0.7), -1.8em -1.8em 0 0em #000000;
  }
}

