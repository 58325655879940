.link-underline {
  &:hover {
    text-decoration: underline;
  }
}

.link-no {
  &:hover {
    text-decoration: none;
  }
}

.link-black {
  color: #000;
}