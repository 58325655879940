#map {
	height: 736px;
	&.contact-map {
		height: auto;
		padding-bottom: 56.25%;
	}
}
#map.proyectos{
	height: 600px;
}
.map-c {
	font-family: $text-font-stack;
	padding: 2rem .5rem;
}
.map_h1 {
	font-size: 2.4rem;
	line-height: 1.15;
	letter-spacing: (50/1000) * 1rem;
}
.map_h2 {
	font-size: 1.8rem;
	letter-spacing: (100/1000) * 1rem;
}
.map_h3 {
	font-size: 1.6rem;
	color: #999;
	padding-top: 1.4rem;
}
.map_img {
	margin-top: 1.6rem;
}