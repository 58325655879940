// ==========================================================================
// Outlet
// ==========================================================================
//
// Table of Contents:
//
//  1. Headings
//  2. DL
//  3. Form
//  4. Table
//  5. Buttons
//  6. Intro
//	7. Outlet item
//	8. User
//	9. Alert
//	10. Filters


// 1. Headings
// ==========================================================================
.h2-out {
	font-size: 2rem;
	line-height: (44/34);
	letter-spacing: (100/1000) * 1rem;
	@media (min-width: 993px) {
		font-size: 3.4rem;
	}	
}
.h3-out {
	font-size: 1.8rem;
	line-height: (30/20);
	margin-bottom: .25em;
	@media (min-width: 993px) {
		font-size: 2rem;
	}	
}

// 2. DL
// ==========================================================================
.outlet-dl {
	dd {
		line-height: (24/18);
		margin-left: 0;
		margin-bottom: 1em;
		padding-bottom: .5em;
		border-bottom: 1px solid #000;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;			
		}
	}
	@media (min-width: 993px) {
		font-size: 1.8rem;
	}
}

// 3. Form
// ==========================================================================
.form-out {
	padding: 2rem 2rem 6rem 2rem;
}


// 4. Table
// ==========================================================================
.table-out {
	border-top: 3px solid #000;
	line-height: 1.25;
	.th {
		font-size: 1.4rem;
		letter-spacing: (20/1000) * 1rem;
	}
	tr {
		td {
			padding-left: 2rem;
			padding-right: 2rem;
			border-right: 1px solid #000;
			border-bottom: 1px solid #000;

			&:last-child { border-right: none; }
		}
	}
}


// 5. Buttons
// ==========================================================================
.btn-outlet {
	margin-top: 1.5rem;
}

// 6. Intro
// ==========================================================================
.intro-out {
	a {
		color: inherit;
		text-decoration: underline;
	}
}

// 7. Outlet item
// ==========================================================================
.out-wrapper {
	margin-top: 6rem;
}
.out-c {
	flex-basis: 100%;
	margin-bottom: 4rem;
	text-decoration: none;
  	&:hover, &:focus {
		text-decoration: none;
		opacity: .85;
	}
	img {
		padding-bottom: 1rem;
	}
	.btn-std {
		display: block;
		font-size: 1.2rem;
		letter-spacing: (180/1000) * 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.out-c_content {
		&:nth-of-type(1) {
			justify-content: space-between;
		}
		&:nth-of-type(2) {
			align-items: baseline;
			line-height: 1.8rem;
		}		
	}
	@media (min-width: 568px) {
	    flex-basis: 50%;
	    max-width: 50%;
	}	
	@media (min-width: 895px) {
		flex-basis: (100 / 3) * 1%;
		max-width: (100 / 3) * 1%;	
	}
	@media (min-width: 1201px) {
		flex-basis: 25%;
		max-width: 25%;
	}	
	@media (min-width: 1600px) {
		flex-basis: 20%;
		max-width: 20%;
	}	
}
.out-c_content {
	padding-bottom: .5rem;
}
.out-c_title {
	padding-right: 2rem;
}
.out-c_price {
	letter-spacing: (60/1000) * 1rem;
}
.out-c_stock {
	font-size: 1.2rem;
	letter-spacing: (200/1000) * 1rem;
	padding-right: 1rem;
}
.out-c_m {
	letter-spacing: (60/1000) * 1rem;
}

.out-c_discount {
	.badge {
		font-size: 1.4rem;
		float: none;
		margin: 0;
		padding: 1rem 2rem;
		background: $primary;
		color: #000;
		height: auto;
		line-height: 1;
	}
}

// 8. User
// ==========================================================================
.side-nav li.user-c {
	line-height: 1.2;
}
.user-c .icon, .user-c a {
	color: rgba(0,0,0,.5);
}
.user-c a {
	//text-transform: ;
}
.user-d {
	text-transform: none;
	margin-right: 1.5rem;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    font-size: 1.4rem;
    letter-spacing: normal;
    .icon {
    	color: rgba(0,0,0,.5);
    	margin-right: .2rem;
    }	
   	strong {
   		margin-right: .2rem;
   	}
   	a {
   		margin-left: .2rem;
   		color: rgba(0,0,0,.5);
   	}
}

// 9. Alert
// ==========================================================================
.form-alert {
	padding: 1rem;
	line-height: 1.1;
	span {
		padding-left: 1rem;
	}
}

// 10. Filters
// ==========================================================================
.filters {
	.btn-filters-outlet {
		font-size: 1.2rem;
		letter-spacing: (180/1000) * 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		margin-top: 2rem;
	}

	@media (max-width: 600.99px) {
		form {
			.filters-item-range {
				.filter-range {
					width: 100%;
				}
			}
		}
	}

	.filter-range {
		padding-top: 1rem;
		padding-left: 3.1rem;
		.title {
			font-weight: bold;
		}
		.range_container {
			display: flex;
			flex-direction: column;
			width: 90%;
			margin: 0 auto;
			.sliders_control {
				position: relative;
				min-height: 50px;
				&:after {
					position: absolute;
					content: "";
					top: 19px;
					left: 0;
					right: 0;
					height: 1px;
					background-color: #C6C6C6;
					z-index: -1;
				}
			}

			.form_control {
				position: relative;
				display: flex;
				justify-content: space-between;
				font-size: 24px;
				color: #635a5a;
				width: 100%;
			}

			input[type="range"] + .thumb {
				display: none;
			}

			input[type=range]::-webkit-slider-thumb {
				-webkit-appearance: none;
				pointer-events: all;
				width: 24px;
				height: 24px;
				background-color: #fff;
				border-radius: 50%;
				box-shadow: 0 0 0 1px #C6C6C6;
				cursor: pointer;
			}

			input[type=range]::-webkit-slider-runnable-track,
			input[type=range]::-moz-range-track {
				width: auto;
				height: auto;
				background: none;
				border-radius: 0;
			}

			input[type=range]::-moz-range-thumb {
				-webkit-appearance: none;
				pointer-events: all;
				width: 24px;
				height: 24px;
				background-color: #fff;
				border-radius: 50%;
				box-shadow: 0 0 0 1px #C6C6C6;
				cursor: pointer;
				border: 0;
			}

			input[type=range]::-webkit-slider-thumb:hover {
				background: #f7f7f7;
			}

			input[type=range]::-webkit-slider-thumb:active {
				box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
				-webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
			}

			input[type="number"] {
				color: #8a8383;
				width: auto;
				height: 30px;
				font-size: 18px;
				border: none;
			}

			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				opacity: 1;
			}

			input[type="range"] {
				-webkit-appearance: none;
				appearance: none;
				height: 2px;
				width: 100%;
				position: absolute;
				background-color: transparent;
				pointer-events: none;
			}

			#fromSlider {
				height: 0;
			}
		}

	}
}