// Arquitectura & Interiorismo
// ==========================================================================
.arquitectura{
	.our-intro {
		@media (min-width: 993px){
			font-size: 2rem;
		}
	}
	.descargas{
		.breadcrumb{
			font-size: 18px;
		}
		button{
    		background-color: $primary;
    		border-color: #fff;
    		text-transform: uppercase;
    		padding: 0.5rem 2rem;
    		span{
    			color: #fff!important;
    		}
		}
		.descargas__filters{
			float: left;
			width: 100%;
			border-bottom: 1px solid;
			li{
				ul li{
					padding: 0;
				}
				a{
					border: 0!important;
					letter-spacing: 1px;
					&.dropdown-button-disable{
						cursor: default;
					}
				}
			}
			@media (min-width: 768px) {
				display: inline-flex;
				li{
					padding-right: 3rem;
				}
				.tipo{
					width: 15%;
				}
				.nombre{
					width: 65%;
				}
				/*.fecha{
					width: 20%;
				}*/
				/* .autor{
					width: 20%;
				} */
				.peso{
					width: 20%;
				}
			}
		}
		.descargas__list{
			li{
				&:not(:last-child){
					border-bottom: 1px solid #dee1ea;
				}
				&.navigable{
					.checkbox{
						cursor: pointer;
						&:hover{
							background: rgba(0,0,0,.1);
						}
					}
				}
			}
		}
		.checkbox{
			width: 100%;
			display: inline-flex;
			align-items: center;
    		padding: 1rem 0;
			.form-check{
				width: 5%;
				span{
					display: none;
				}
			}
			.icono{
				width: 15%;
				img{
				    width: 55px;
    				margin: 0;
				}
				.folder{
					width: 40px;
    				margin-left: 0.6rem;
				}
			}
			span{
				font-size: 14.5px;
			}
			.nombre{
				width: 65%;
				font-weight: 500;
			}
			/*.fecha{
				width: 20%;
				span{
					font-size: 14px;
				}
			}*//*
			.autor{
				width: 20%;
				span{
					span{
						opacity: 0.5;
					}
				}
			} */
			.peso{
				width: 20%;
			}
			@media (max-width: 767px) {
				flex-wrap: wrap;
				.form-check{
					width: 10%;
				}
				.icono{
					width: 20%;
				}
				.nombre{
					width: 70%;
				}
				.fecha{
					width: 20%;
				}
				/* .autor{
					width: 80%;
				} */
				.peso{
					width: 80%;
				}
			}
		}
	}
}