// ==========================================================================
// Header
// ==========================================================================
//
// Table of Contents:
//
//  1. Menu
//  2. Scroll event



// 1. Menu
// ==========================================================================
.c-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9000;
	background-color: #fff;
	box-shadow: none;
	height: 7.5rem;
	transition: height 0.3s cubic-bezier(0.77, 0, 0.175, 1);
	a {
		color: #000;
	}
	@media (min-width: 1201px) {
		height: 12rem;
	}
}
.nav-wrapper {
	@media (min-width: 1201px) {
		padding-left: 6rem;
		padding-right: 6rem;
	}
}
.c-nav_left {
	@media (min-width: 1201px) {
		padding-bottom: 1.5rem;
	}
}
.c-nav_right {
	margin-left: auto;
	.c-nav_secondary {
		a {
			margin-right: 4.9rem;
		}

		form {
			margin-right: 4.9rem;

			input[type="checkbox"] {
				display: none;
			}

			button[type="submit"] {
				height: 1.6rem;
				line-height: 1.6rem;
				border: 0;
				background: #000000;
				border-radius: 50%;
				color: #FFFFFF;
				text-decoration: none;
				padding: 0 .45rem;

				&:hover {
					color: #000000;
					background: transparent;
				}
			}
		}
		.dropdown-button {
			margin-right: 0;
		}
	}
	.dropdown-button {
		i {
			display: inline-block;
			height: auto;
			font-size: 1.8rem;
			line-height: 1;
		}
	}
}

.c-nav_submenu {
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100vw;
	overflow: hidden;
	-ms-transform-origin: center top;
	transform-origin: center top;
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	transition: transform .3s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
	padding: 4rem 6rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: end;
	z-index: -1;
	&.c-nav_submenu_filters {
		padding-left: 15rem;
		padding-right: 15rem;
	}
}
.c-nav_submenu_list {
	font-size: 1.4rem;
	line-height: (25/20);
	& + .c-nav_submenu_list {
		margin-left: 4.9rem;
	}
	li {
		float: none;
		margin-bottom: 2rem;
		transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		&:last-child { margin-bottom: 0; }
	}
	a {
		display: inline-block;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: #000;
			-ms-transform: scaleX(0);
			transform: scaleX(0);
			transition: transform .3s;
			-ms-transform-origin: left;
			transform-origin: left;
		}
		&:hover {
			text-decoration: none;
			&:after { -ms-transform: scaleX(1); transform: scaleX(1); }
		}
	}
	@media (min-width: 1400px) {
		font-size: 1.8rem;
	}
	.c-nav_submenu_title {
		& + .c-nav_sub_submenu_list {
			margin-top: 1.5rem;
		}
	}
	.c-nav_sub_submenu_list {
		font-weight: 400;
		a {
			font-weight: 400;
		}
		li {
			margin-bottom: .5rem;
			&.extra{
				&__top{
					margin-top: 2rem;
				}
			}
		}
		&.c-nav_colors {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			transform: rotate(90deg);
			grid-gap: 2rem;
			margin-top: 4rem;
			margin-left: -3rem;
			li {
				margin: 0;
				a:hover {
					&:after {
						display: none;
					}
				}
			}
			@media (min-width: 1200px) and (max-width: 1399.98px) {
				li {
					img {
						max-width: 30px;
						height: auto;
					}
				}
			}
		}
	}
}
.c-nav-span {
	font-size: 1.4rem;
	cursor: pointer;
	@media (min-width: 1400px) {
		&:after { margin-left: 2.5rem; }
	}
}
.c-nav_menu_item {
	padding-bottom: 1.5rem;
	&:not(:last-child) { margin-right: 6.6rem; }
	.c-nav-span {
		font-weight: 500;
		transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
	&.active {
		.c-nav-span {
			text-decoration: underline
		}
	}
	&:hover {
		.c-nav-span { text-decoration: underline }
		.c-nav_submenu {
			-ms-transform: scaleY(1);
			transform: scaleY(1);
			transition: transform .5s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
		.c-nav_submenu_overlay {
			-ms-transform: translateY(-100%);
			transform: translateY(-100%);
			transition: transform .5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
		}
	}
}
.c-nav_submenu_img {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	width: 413px;
	overflow: hidden;
	margin-right: 3rem;
}
.c-nav_submenu_overlay {
	position: absolute;
	top: -5px;
	bottom: -5px;
	left: -5px;
	right: -5px;
	background-color: #fff;
	z-index: 10;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	visibility: visible !important;
	-ms-transform: translateY(0);
	transform: translateY(0);
	transition: transform .5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.c-nav_logo {
	transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.c-nav_secondary {
	font-size: 1.2rem;
	margin-bottom: 4.3rem;
	transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.accordion-icon {
	position: relative;
	width: 10px;
	height: 10px;
	overflow: hidden;
	transition: transform .4s cubic-bezier(0.4, 0, 0.2, 1);
	transition-delay: .2s;
	&:before, &:after {
		content: '';
		position: absolute;
		background-color: #000;
	}
	&:before {
		width: 100%;
		height: 2px;
		top: 50%;
		margin-top: -1px;
		transition: transform .4s cubic-bezier(0.4, 0, 0.2, 1);
		transition-delay: 0s;
	}
	&:after {
		width: 2px;
		height: 100%;
		left: 50%;
		margin-left: -1px;
	}
}
.side-nav {
	li.active {
		.accordion-icon {
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		.accordion-icon:before {
			transition-delay: 0.2s;
			-ms-transform: translate(100%, 0);
			transform: translate(100%, 0);
		}
	}
}
.mobile-li {
	padding: 0 3rem;
	ul {
		display: -ms-flexbox;
		display: flex;
	}
	li {
		margin-right: 5px;
		&:last-child { margin-right: 0; }
	}
	a {
		padding: 0;
	}
}

.btn.btn-header-search {
	position: absolute;
	right: 6rem;
	bottom: 4rem;
	background: transparent;
	border: 1px solid #000;
	color: #000;
	border-radius: 2.6rem;
	text-transform: none;
	font-size: 1.8rem;
	line-height: 1.8rem;
	font-weight: 500;
	padding: .5rem 1.7rem;
	&:hover {
		text-decoration: none;
		box-shadow: inset 0 0 3px rgba(0,0,0,.4);
	}
}


// 2. Scroll event
// ==========================================================================
@media (min-width: 1201px) {
	.c-nav.scrolled {
		height: 7.6rem;
		.c-nav_secondary {
			visibility: hidden;
			opacity: 0;
			-ms-transform: translateY(-100%);
			transform: translateY(-100%);
			padding: 0;
		}
	}
}

.c-nav_menu {
	li {
		&:last-child {
			.c-nav-span {
				&:after {
					content: none;
				}
			}
		}
	}
}

// 3. Special home
// ==========================================================================
.body-home {
	.c-nav:not(.scrolled) {
		background: transparent;
		.nav-wrapper {
			background: transparent;
			.c-nav_logo {
				filter: invert(100%);
			}
			.c-nav-span, a {
				color: #FFFFFF;
			}
			#mobile-menu {
				a {
					color: #000;
				}
			}
			.c-nav_submenu {
				background: transparent;
				transition: none;
			}
			.c-nav_submenu_overlay {
				display: none;
			}
			.button-collapse {
				span {
					background: #FFF;
				}
			}
			.c-nav_right {
				.c-nav_secondary {
					form {
						button {
							color: #000000;
							background: #FFFFFF;

							&:hover {
								background: transparent;
								color: #FFFFFF;
							}
						}
					}
				}
			}
			#dropdown-lang {
				a {
					color: #000;
				}
			}
			.c-nav_submenu_list {
				a {
					&:after {
						background-color: #FFF;
					}
				}
				.c-nav_submenu_title {
					color: #FFF;
				}
			}
			.btn.btn-header-search {
				border-color: #FFF;
				color: #FFF;
			}
		}
	}
}