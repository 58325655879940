// ==========================================================================
// Typography
// ==========================================================================
//
// Table of Contents:
//
//  1. Headings



// 1. Headings
// ==========================================================================
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}
h1, .h1 {
	display: inline-block;
	font-size: 2rem;
	line-height: 2.5rem;
	margin-bottom: 1.1rem;
	font-weight: 500;
	&.no-bottom {
		margin-bottom: 0;
	}
}
.h2-home {
	display: inline-block;
	font-size: 4rem;
	line-height: 1;
	margin-bottom: 1rem;
	@media (min-width: 993px) {
		font-size: 6.8rem;
		margin-bottom: 3rem;
	}
	@media (min-width: 1400px) {
		font-size: 8rem;
	}
	&__underline{
		width: 100%;
		padding-bottom: 1rem;
		border-bottom: 2px solid #000;
	}
}

.h3-home {
	font-size: 2.4rem;
	@media (min-width: 993px) {
		font-size: 3rem;
	}
}

.h2-outlet {
	font-size: 3.4rem;//44
}
.h2-large {
	font-size: 2rem;//52
	@media (min-width: 601px) {
		font-size: 3.6rem;
	}
	@media (min-width: 1400px) {
		font-size: 4.8rem;
	}	
}


.h32 {
	font-size: 1.8rem;
	margin-bottom: 1rem;
	@media (min-width: 601px) {
		font-size: 2.4rem;
	}
	@media (min-width: 1400px) {
		font-size: 3.2rem;
	}
}

.fw-300 { font-weight: 300; }
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }

.lh-1 { line-height: 1 }

.text-line-through {text-decoration: line-through; }
.fs-14{font-size: 1.4rem;}
.c-red { color: red; }
.mb-p5 { margin-bottom: .5rem; }