// ==========================================================================
// Manual
// ==========================================================================
//
// Table of Contents:
//
//  1. Mosaic List

// 1. Mosaic List
// ==========================================================================
.mosaic-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, calc(-0.75rem + 50%));
  @media (min-width: 993px) {
    grid-gap: 3rem;
    grid-template-columns: repeat(4, calc(-3rem + 25%));
  }
  &__img{
    margin-bottom: .6rem;
    @media (min-width: 601px) {
      margin-bottom: 1.1rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: .7rem;
    @media (min-width: 601px) {
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 0;
    }
  }
  a {
    position: relative;
    img {
      transition: transform 500ms;
    }
    &:hover {
      text-decoration: none;
      img {
        transform: scale(1.5);
      }
    }
  }
  li {
    .like{
      opacity: 0;
      @media (max-width: 767px) {
        opacity: 1;
        bottom: -3px;
      }
    }
    &:hover{
      .like{
        opacity: 1;
      }
    }
  }
  .like{
    position: relative;
    z-index: 300;
    .d-flex{
      position: absolute;
      right: 0;
      top: -56px;
      max-width: fit-content;
      justify-content: flex-end;
      background-color: #fff;
      padding: .2rem .5rem .2rem .5rem;
      cursor: default;
    }
    .heart{
      width: 22px!important;
      height: 21px!important;
      //margin-right: 0.5rem;
      background-image: url(../images/galeria/heart.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      &.yes{
        background-image: url(../images/galeria/heart-green.svg);
        background-size: contain;
        cursor: not-allowed;
      }
    }
    span{
      color: #000;
    }
  }
}