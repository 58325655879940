// ==========================================================================
// Manual
// ==========================================================================
//
// Table of Contents:
//
//  1. Intro
//  2. Form
//  3. Button
//  4. Primer bloque
//	5. Bloque gris


// 2. Form
// ==========================================================================
.manual-form-title {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 5.25rem;
	strong {
		color: $primary;
		font-weight: inherit;
	}
	@media (min-width: 993px) {
		font-size: 2.4rem;
		margin-bottom: 10.5rem;
	}
}


// 3. Button
// ==========================================================================


// 4. Primer bloque
// ==========================================================================
.manual-1 {
	margin-top: 4.5rem;
	margin-bottom: 11.4rem;
	.video-instalacion {
		.info {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			height: 100%;
			width: 100%;
			img {
				filter: invert(100%);
				position: absolute;
				left: 50%;
				bottom: 5.5rem;
				transform: translateX(-50%);
				@media (max-width: 992.98px) {
					bottom: auto;
					top: 1rem;
					max-width: 50%;
					height: auto;
				}
			}
			.video-btn {
				background-color: transparent;
				position: relative;
				top: 50%;
				left: 50%;
				width: auto;
				height: auto;
				border-radius: 0;
				color: #FFFFFF;
				transform: translate(-50%,-50%);
				display: block;
				&:after {
					display: none;
				}
				span {
					width: 0;
					height: 0;
					border-top: 3.3rem solid transparent;
					border-bottom: 3.3rem solid transparent;
					border-left: 6.6rem solid #FFFFFF;
					display: inline-block;
					@media (max-width: 992.98px) {
						border-top: 1.8rem solid transparent;
						border-bottom: 1.8rem solid transparent;
						border-left: 3.6rem solid #FFFFFF;
					}
				}
			}
		}
		&.active {
			.info {
				display: none;
			}
		}
	}
}


// 5. Bloque pasos
// ==========================================================================
.manual-2 {
	h2 {
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 5.8rem;
	}
	.media {
		position: relative;
		.manual-status {
			display: none;
			position: absolute;
			left: 2.8rem;
			top: .8rem;
			font-size: 4rem;
			line-height: 4rem;
			font-weight: 500;
			color: #FFFFFF;
			z-index: 10;
			text-shadow: 1px 1px rgba(0,0,0,.7);
		}
	}
	.info {
		padding-top: .8rem;
		counter-reset: section;
		position: relative;
		h3 {
			font-size: 4rem;
			line-height: 1;
			margin-bottom: 4.4rem;
			&:before {
				counter-increment: section;
				content: counter(section) "- ";
			}
		}
		.manual-slide-text {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		.manual-nav {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			@media (max-width: 600.99px) {
				position: relative;
				margin-top: 2rem;
			}
			button {
				.arrow {
					border: solid black;
					border-width: 0 3px 3px 0;
					display: inline-block;
					padding: 3px;
				}
				&.manual-previous {
					.arrow {
						margin-right: 2.5rem;
						transform: rotate(135deg);
					}
				}
				&.manual-next {
					.arrow {
						margin-left: 2.5rem;
						transform: rotate(-45deg);
					}
				}
				&[disabled]{
					.arrow {
						border-color: rgba(0,0,0,.5);
					}
				}
			}
		}
	}
}
/*
.manual-2 {
	padding-top: 4rem;
	padding-bottom: 4rem;
	margin-bottom: 4rem;
	@media (min-width: 601px) {
	padding-top: 8rem;
	padding-bottom: 8rem;
	margin-bottom: 8rem;
	}
}
.slick-manual {
	margin-bottom: 2rem;
}
.manual-slide-title {
	font-size: 2rem;
	font-weight: 700;
	letter-spacing: (100/1000) * 1rem;
	text-transform: uppercase;
	margin-top: 2rem;
	@media (min-width: 601px) {
		font-size: 3rem;
		margin-top: 5rem;
	}
}
.manual-slide-text {
	font-size: 1.8rem;
	letter-spacing: (20/1000) * 1rem;
}
.manual-status {
	color: #dcdcdc;
	font-size: 2.4rem;
	font-weight: 500;
	letter-spacing: (20/1000) * 1rem;
	margin-bottom: 2rem;
	@media (min-width: 601px) {
		margin-bottom: 6.9rem;
	}
}
.slick-manual-pics {
	.slick-list {
		//padding: 2rem;
	}
	.slick-track {
		//padding-bottom: 56.25%;
	}
	.slick-slide {
		background-color: #fdfdfd;
		padding: 2rem;
		box-shadow: 0 5px 12px 0 rgba(0,0,0,0.035);
		    -ms-transform: translate(2rem, 2rem);
		        transform: translate(2rem, 2rem);
		transition: transform .35s ease-out, opacity .25s .15s ease-in !important;
	}
	.slick-active {
		    -ms-transform: translate(0, 0);
		        transform: translate(0, 0);
		background-color: #fff;
	}
	img {

	}
	@media (min-width: 601px) {
		&:before {
			content: '';
			background: #fff;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 20px;
			top: 20px;
			box-shadow: 0 5px 12px 0 rgba(0,0,0,0.035);
			opacity: .5;
		}
	}
}
.manual-next {
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: (100/1000) * 1rem;
	cursor: pointer;
	svg {
		margin-right: 2.2rem;
		transition: transform .5s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
    &:hover {
    	svg {
			-ms-transform: translateX(12px);
			transform: translateX(12px);
    	}
    }
}
.manual-replay {
	display: none;
	cursor: pointer;
	margin-right: 2.2rem;
	&:hover {
		i { -ms-transform: rotate(-360deg); transform: rotate(-360deg); }
	}
	i {
		transition: transform .45s ease-in-out;
	}
}
.flex.manual-replay {
	display: none;
}
.flex.manual-replay.active {
	display: -ms-flexbox;
	display: flex;
}

.slick-manual {
	counter-reset: section;
	h3 {
		&:before {
			counter-increment: section;
			content: counter(section) "- ";
		}
	}
} */