// ==========================================================================
// Contacto
// ==========================================================================
//
// Table of Contents:
//
//  1. Map




// 1. Map
// ==========================================================================

.contact-map {
	margin-bottom: 8rem;
}
@media (max-width: 992px) {
	.contact-info {
		margin-top: 1em;
	}
}
.map-box {
	i {
		opacity: .3;
		padding-right: .5rem;
	}
}