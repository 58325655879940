// ==========================================================================
// Intro
// ==========================================================================
//
// Table of Contents:
//
//  1. Intro



// 1. Intro
// ==========================================================================
.intro-default {
	font-size: 1.8rem;
	line-height: (24/20);
	letter-spacing: (50/1000) * 1rem;
	margin-bottom: 3rem;
	@media (min-width: 601px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) {
		font-size: 2rem;
		margin-bottom: 6rem;
		&.intro-instalaciones{
			margin-top: 6rem;
		}
	}	
}