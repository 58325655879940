// ==========================================================================
// Footer styles
// ==========================================================================
//
// Table of Contents:
//
//  1. General
//  2. Footer logo and lema
//  3. Newsletter


// 1. General
// ==========================================================================

footer {
	margin-top: 7rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	@media(min-width: 993px) {
		padding-top: 7.5rem;
		padding-bottom: 11.8rem;
		margin-top: 10rem;
	}
}

// 2. Footer logo and lema
// ==========================================================================
.footer-logo, .footer-lema{
	margin-bottom: 6rem;
	img {
		max-width: 11.6rem;
		height: auto;
		@media (min-width: 601px) {
			max-width: 100%;
		}
	}
}

// 3. Newsletter
// ==========================================================================
.footer-newsletter {
	margin-bottom: 4rem;
	p {
		line-height: 2rem;
	}
	[type="email"] {
		margin-bottom: 2.2rem !important;
	}
	.checkbox-c {
		margin-top: 0 !important;
	}
	[type="checkbox"] {
		&:checked + label::before {
			border-right-color: #000;
			border-bottom-color: #000;
		}
	}
	[type="submit"] {
		margin-top: 2.5rem;
	}
	#footer-form-error {
		margin-top: .5rem;
	}
	@media (min-width: 993px) {
		margin-bottom: 0;
	}
}