// ==========================================================================
// Catalog
// ==========================================================================
//
// Table of Contents:
//
//  1. Catalog item
//  2. New design page catalogues



// 1. Catalog item
// ==========================================================================

.catalog {
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
	margin-bottom: 4rem;
    &.catalog-paragraph{
      display: inline-block;
      width: auto;
    }
}
.catalog-span {
	margin-top: 2.5rem;
	color: #000;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: (180/1000) * 1rem;
	padding: 1.5rem 1rem;
	text-align: center;
	text-transform: uppercase;
	max-width: 32rem;
	width: 100%;
}


.row-sent-email { 
  margin-bottom: 4rem;
  img {
    width: 126px;
    margin: 0 auto;
  }
  .col {
    width: 100%;
  }
  @media screen and (min-width: 992px) {
    img {
      width: 190px;
    }
  }
  @media screen and (min-width: 992px) {
    .col {
      &:first-child {
        width: 200px;
      }
      &:last-child {
        width: 34%;
      }
    }
  }
}

// 2. New design page catalogues
// ==========================================================================
.catalogues {
  &__item {
    margin-bottom: 3rem;
    a {
      text-decoration: none;
      .img {
        padding: 3.5rem 5rem;
        background: #f0f0f0;
        margin-bottom: 1.2rem;
        border-radius: .8rem;
        @media(min-width: 1400px) {
          padding: 8.5rem 12rem;
          margin-bottom: 1.4rem;
        }
        img {
          mix-blend-mode: multiply;
        }
      }
      .text {
        font-size: 1.5rem;
        @media(min-width: 993px) {
          font-size: 1.8rem;
        }
      }
      &:hover {
        .img {
          background: none;
        }
        .text {
          text-decoration: underline;
        }
      }
    }
  }
}