// ==========================================================================
// Forms
// ==========================================================================
//
// Table of Contents:
//
//  1. Button
//  2. Horizontal
//  3. Validation
//  4. DangerChrome autocomplete
//  5. Chrome autocomplete
//  6. Checkbox newsletter
//  7. Extras / modificadores



// 1. Button
// ==========================================================================
.btn-form {
	background-color: $primary;
	border: none;
	font-weight: 500;
	padding: 1.7rem 0;
	width: 100%;
}
.checkbox-c {
	position: relative;
	margin-bottom: 2.6rem;
	[type=checkbox]+label { display: block; }
}
#aside-form, #pie-form{
	height: 100px;
}

// 2. Horizontal
// ==========================================================================
.form-h {
	input[type=text]:not(.browser-default),
	input[type=email]:not(.browser-default),
	input[type=password]:not(.browser-default),
	.select-wrapper:not(.has-error) input.select-dropdown {
		margin-bottom: 4.5rem;
	}

	.input-field.has-error,
	.select-wrapper.has-error {
		position: relative;
		.invalid-feedback {
			position: absolute;
			bottom: 1.5rem;
			left: 0;
		}
	}

	.select-wrapper.has-error {
		.invalid-feedback {
			bottom: -3rem;
		}
	}
}

// 3. Validation
// ==========================================================================
.form-error {
	position: relative;
	font-size: 1.5rem;
	line-height: 1;
    color: #ff6d6d;
    padding: .4rem 0;		
}
.input-field {
	.form-error {
	    position: absolute;
	    bottom: 2.2rem;
	    width: 100%;
	    left: 0;

	}
}
.select-field {
	.form-error {
	    bottom: -23px;
	}
}
.select-wrapper.has-error  input.select-dropdown {
	border-color: rgb(185, 74, 72);
}
.select-wrapper.has-success input.select-dropdown {
	border-color: #4CAF50;
}
.checkbox-c {
	.form-error {
		display: inline-block;
		padding-top: .5rem;
	}
}
.success.card-panel {
	margin-top: 2rem;
	margin-bottom: 2rem;
	i { padding-right: 1rem; }
}
input[type=text]:not(.browser-default).fake-valid,
textarea.materialize-textarea.fake-valid {
	border-bottom: 1px solid #4CAF50;
}

.help-block {
	width: 100%
}

// 4. Danger
// ==========================================================================
.alert {
	margin-bottom: 1.5rem;
	background-color: #fcf8e3;
	border: 1px solid #faebcc;
	color: #8a6d3b;
}


// 5. Chrome autocomplete
// ==========================================================================
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
}

// 6. Checkbox newsletter
// ==========================================================================
#form_newsletter, #form_newsletter_blog {
  .checkbox-c {
    margin-top: 1.7rem;
    margin-bottom: 0;
    text-align: left;
    label {
      height: auto;
      line-height: 1;
      &:before, &:after {
        margin-top: 0;
      }
    }
  }
}

#form_newsletter {
	[type="checkbox"] {
		+ label {
			padding-left: 18px;
			height: auto;
			line-height: 1.2;
		}
	
		/* checkbox aspect */
		+ label:before,
		&:not(.filled-in) + label:after {
			width: 10px;
			height: 10px;
			margin-top: 0;
		}
		&:checked {
			+ label:before {
				top: -2px;
				left: -2px;
				width: 6px;
			}
		}
	}
}

// 6. Recaptcha info
// ==========================================================================
.recaptcha-label{
	margin-bottom: 2.6rem;
}
.grecaptcha-badge{
	display: none;
}

.invalid-feedback {
	color:rgb(185, 74, 72);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

// 7. Extras / modificadores
// ==========================================================================
.checkbox-c {
	&.fs-10 {
		[type="checkbox"]:checked + label::before {
			top: 2px;
			left: 13px;
			width: 7px;
			height: 14px;
		}
	}
}

#form-pie {
	@media(max-width: 992.99px) {
		.form-checks-button {
			flex-wrap: wrap;
			.recaptcha-label {
				flex: 0 0 100%;
				& + .checkbox-c {
					flex: 0 0 100%;
					& + div {
						flex: 0 0 100%;
					}
				}
			}
		}
	}
}