// X. Flex
// ==========================================================================
.d-flex {
  display: -ms-flexbox;
  display: flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-column { flex-direction: column }
.flex-column-reverse { flex-direction: column-reverse }
.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-content-between { justify-content: space-between }
.justify-content-start { justify-content: start }
.justify-content-end { justify-content: end }
.align-items-center { align-items: center }
.align-items-end { align-items: flex-end }
.f-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.f-gutter-1 {
  margin-left: -1rem
}
.f-gutter-1 > .col {
  padding-left: 1rem
}
.f-gutter-4 {
  margin-left: -4rem
}
.f-gutter-4 > .col {
  padding-left: 4rem
}

.f-grow { flex-grow: 1 }

.f-col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.f-order-0 {
  order: 0;
}

.f-order-1 {
  order: 1;
}

.f-order-2 {
  order: 2;
}

.f-col-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 601px) {
  .f-col-m1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (1*100) / 12 * 1%;
    max-width: (1*100) / 12 * 1%;
  }
  .f-col-m2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (2*100) / 12 * 1%;
    max-width: (2*100) / 12 * 1%;
  }
  .f-col-m3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .f-col-m4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (4*100) / 12 * 1%;
    max-width: (4*100) / 12 * 1%;
  }
  .f-col-m5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (5*100) / 12 * 1%;
    max-width: (5*100) / 12 * 1%;
  }
  .f-col-m6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .f-col-m7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (7*100) / 12 * 1%;
    max-width: (7*100) / 12 * 1%;
  }
  .f-col-m8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (8*100) / 12 * 1%;
    max-width: (8*100) / 12 * 1%;
  }
  .f-col-m9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .f-col-m10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (10*100) / 12 * 1%;
    max-width: (10*100) / 12 * 1%;
  }
  .f-col-m11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: (11*100) / 12 * 1%;
    max-width: (11*100) / 12 * 1%;
  }                   
  .f-col-m12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .justify-content-md-between { justify-content: space-between }
}

@media (min-width: 993px) {
  .d-lg-none { display: none }
  .justify-content-lg-start { justify-content: start }
  .justify-content-lg-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .order-lg-0 { order: 0 }
  .order-lg-1 { order: 1 }
  .order-lg-2 { order: 2 }
  .order-lg-3 { order: 3 }

  .f-col-l-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}

@media (min-width: 1201px) {
  .align-items-xl-end { align-items: flex-end }
  .d-xl-flex {
    display: -ms-flexbox;
    display: flex;
  }
}