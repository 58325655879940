.slick-cs {
	margin-top: 1rem;
	margin-bottom: 2rem;
	.parallax-container{
		position: relative;
		&:after{
		    background: rgba(0,0,0,0) linear-gradient(transparent,rgba(0,0,0,.6)) repeat scroll 0 0;
		    bottom: 0;
		    content: "";
		    height: 30%;
		    left: 0;
		    position: absolute;
		    width: 100%;
		    z-index: 2;
		}
	}
	.datos-piscina{
		position: absolute;
		width: 100%;
		padding-left: 2rem;
		bottom: 0;
		z-index: 3;
		h4, h5{
			position: relative;
			color: #fff;
		}
		h4{
			font-size: 20px;
			margin-bottom: 1rem;
			text-transform: uppercase;
		}
		h5{
			font-size: 18px;
		}
	}
	&.slick-dotted.slick-slider { margin-bottom: 2rem; }
	.parallax-container {
		height: 30rem;
	}
	@media (min-width: 601px) {
		margin-top: 2rem;
		margin-bottom: 3rem;
		&.slick-dotted.slick-slider { margin-bottom: 3rem; }
	}
	@media (min-width: 993px) {
		margin-top: 0;
		margin-bottom: 6rem;
		&.slick-dotted.slick-slider { margin-bottom: 6rem; }		
		.parallax-container {
			height: 50rem;
		}
	}
	@media (min-width: 1400px) {
		margin-top: 4.3rem;
	}
}

.slick-slider {
	iframe {
		width: 100%;
	}
}


.slick-detail {
	width: 100%;
	max-width: 627px;
	margin: 0 auto;
	.slick-slide {
		width: 100%;
		height: auto;
	}
  .slick-video.slick-slide {
    .flex {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .lazyYT {
      width: 100%;
    }
  }
}

.slick-img {
	&:before {
		content: '';
		display: block;
		width: 100%;
		content: "";
		padding-bottom: 66.6666666667%;
		height: 0;
	}
}