// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hide-till-desktop {
  @media (max-width: 1200px) { display: none; }
}

// X. Sizes
// ==========================================================================
.w-100 { width: 100%; }
.h-100 { width: 100%; }

// X. Borders
// ==========================================================================
.border-0 {border: 0}

// X. Text
// ==========================================================================
.text-u { text-transform: uppercase }
.text-center { text-align: center }
.text-left { text-align: left }
.no-margin { @include no-last-el-margin }
.text-underline { text-decoration: underline }
@media (min-width: 601px) {
  .text-md-center { text-align: center }
}

// X. Display
// ==========================================================================
.d-block { display: block }
.d-inline-block { display: inline-block }
.d-none { display: none }
@media (min-width: 601px) {
  .d-m-block { display: block;}
  .d-m-none { display: none;}
}
@media (min-width: 993px) {
  .d-l-block { display: block;}
  .d-l-none { display: none;}
  .d-l-flex { display: flex;}
}

// X. Images
// ==========================================================================
.responsive-img { display: block; }
.center-img { margin: 0 auto; }
.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

// X. Color
// ==========================================================================
.c-w {
  color: #fff;
}
.c-gray {
  color: lighten($black, 25%);
}

// X. Background
// ==========================================================================
.bg-primary { background-color: $primary }
.bg-fa {
  position: relative;
  //background-color: #fafafa;
  z-index: 5;
}
.bg-f {
  position: relative;
  background-color: #fff;
  z-index: 5;
}

// X. Vertical alignment with inline-block
// ==========================================================================
.v-mid { vertical-align: middle; }


// X. Paddings/Margins
// ==========================================================================
.pt-5 { padding-top: 5rem }
.pt-25 { padding-top: 2.5rem }
.pb-2 { padding-bottom: 2rem}
.pt-2 { padding-top: 2rem}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pb-12 {
  padding-bottom: 3rem;
  @media (min-width: 601px) {
    padding-bottom: 6rem;
  }
  @media (min-width: 993px) {
    padding-bottom: 12rem;
  }
}
.pl-0 { padding-left: 0 }
.pl-1 { padding-left: 1rem }
.pr-1 { padding-right: 1rem }
.pl-2 { padding-left: 2rem }
.mb-0 { margin-bottom: 0 }
.mb-1 { margin-bottom: 1rem }
.mb-2 { margin-bottom: 2rem }
.mb-3 { margin-bottom: 3rem }
.mt-1 { margin-top: 1rem }
.mt-2 { margin-top: 2rem }
.mt-3 { margin-top: 3rem }
.mt-4 { margin-top: 4rem }
.mt-6 { margin-top: 6rem }
.ml-1 { margin-left: 1rem }
.px-p5 {
  padding-left: .5rem;
  padding-right: .5rem;
}
.p-4 {
  padding: 4rem;
}
@media (min-width: 601px) {
  .mb-m-0 { margin-bottom: 0 }
}
@media (min-width: 601px) {
  .mb-m-0 { margin-bottom: 0 }
  .pl-md-2 { padding-left: 2rem }
  .pl-md-5 { padding-left: 5rem }
  .pl-md-100 { padding-left: 10rem }
  .pr-md-100 { padding-right: 10rem }
  .pt-md-2 {padding-top: 2rem;}
}

@media (min-width: 993px) {
  .mb-l-0 {margin-bottom: 0}
  .pl-l-0 {padding-left: 0}
  .pl-l-1 {padding-left: 1rem}
  .pl-l-2 {padding-left: 2rem}
  .pr-l-0 {padding-right: 0}
  .pr-l-1 {padding-right: 1rem}
  .pr-l-2 {padding-right: 2rem}
  .pl-l-6 {padding-left: 6rem}
  .pr-l-6 {padding-right: 6rem}
  .px-l-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .px-l-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}


// Placeholder
// ==========================================================================
.gen-input::placeholder {
  font-size: 1.5rem;
  color: #9b9b9b;
  line-height: 1;
}


// scrollReveal
// ==========================================================================
.sr {
  visibility: hidden;
}
.sr-item {
  visibility: hidden;
}
.overflow {
  overflow: scroll;
}
.fs-10 { font-size: 1rem }
.fs-12 { font-size: 1.2rem }
.fs-15 { font-size: 1.5rem }
.fs-20 { font-size: 2rem }
.fs-30 { font-size: 3rem }
.fs-40 { font-size: 4rem }

@media (min-width: 601px) {
  .fs-md-20 { font-size: 2rem }
}
@media (min-width: 993px) {
  .fs-lg-20 { font-size: 2rem }
}
@media (min-width: 1400px) {
  .fs-xl-30 { font-size: 3rem }
}

.h-100 {
  height: 100%;
}
.position-relative {
  position: relative;
}

// Contenido páginas Soluciones, Área Profesional, Área Técnica
// ==========================================================================
.gestionable{
  h2{
    max-width: 838px;
    font-size: 1.8rem;
    margin: 5rem 0 1rem;
    font-weight: 500;
    text-transform: uppercase;
    @media (min-width: 601px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1400px) {
      font-size: 3.2rem;
    }
  }
  .cms-area{
    max-width: 838px;
    h3{
      position: relative;
      text-transform: uppercase;
      padding-left: 5rem;
      margin-top: 3rem;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        margin-top: 1rem;
        background-color: $primary;
        width: 4rem;
        height: .3rem;
      }
      @media (min-width: 601px) {
        font-size: 2rem;
      }
      @media (min-width: 1400px) {
        font-size: 2.5rem;
      }
    }
  }
  .h2-sol{
    &:not(:first-child){
      padding-top: 6rem;
      border-top: 1px solid rgba(0,0,0,.1);
    }
  }
  .bloque{
    max-width: 838px;
    img{
      border: 1px solid rgba(0,0,0,.1);
    }
  }
  .manual-1{
    h2{
      margin-top: 0;
    }
  }
  .video{
    max-width: 838px;
    margin: 2rem 0;
    img{
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 1240px) and (min-width: 993px){
    .l9{
      padding-right: 6rem;
    }
  }
}
// Parrafos mensajes de formulario
// ==========================================================================
.p__msg_form {
  flex: 0 0 100%;
  max-width: 100%;
}


.img-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}

.img-square{
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__95 {
    &:after{
      padding-bottom: 95%;
    }
  }
}

.img-rounded{
  border-radius: 8px;
}