.pagination {
  -ms-flex-pack: center;
  justify-content: center;  
  li {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-left: .5rem;
    margin-right: .5rem;
    a {
      color: #969494;
      display: inline-block;
      font-weight: 500;
      padding: .8rem 1.5rem;
    }
    &.active {
      a {
        color: #000;
      }
      span {
        border-bottom: .1rem solid #000;
      }
    }
    &.disabled a {
      display: none;
    }
    i {
      font-size: 2.6rem;
    }
  }
  li.pages ul li {
    display: inline-block;
    float: none;
  }
}
/* .pagination {
  width: 100%;

  li.prev,
  li.next {
    width: 10%;
  }

  li.pages {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
  }
} */