.cms {
	@include no-last-el-margin;
	strong { font-weight: 500; }
}
.cms-plain {
	h2, h3, h4, h5, h6 {
		margin-bottom: 1rem;
	}
	p, table, ul, ol {
		margin-bottom: 2rem;
	}
	@media (max-width: 600px) {
		h2 { font-size: 2rem; }
		h3 { font-size: 1.8rem; }		
	}
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}
}