// ==========================================================================
// Área profesional
// ==========================================================================
//
// Table of Contents:
//
//  1. Imagen principal
//  2. Video block
//  3. CMS
//  4. x
//  5. x
//  6. x
//	7. x
//	8. x
//	9. x



// 1. Imagen principal
// ==========================================================================
.area-img {
	margin-bottom: 6rem;	
}

// 2. Video block
// ==========================================================================
.video-cms {
	margin-bottom: 4rem;
	.video { margin-bottom: 1.5rem; }
	@media (min-width: 1201px) {
		.video { margin-bottom: 0; }
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		.video {
			flex-basis: 64%;
		}
		.cms {
			flex-basis: 36%;
			padding-left: 4rem;
			margin-bottom: 0;
		}
		&.reverse {
			-ms-flex-direction: row-reverse;
			-moz-flex-direction: row-reverse;
			flex-direction: row-reverse;
			.cms {
				padding-left: 0;
				padding-right: 4rem;
			}
		}
	}
}
.img-area {
	margin-bottom: 1.5rem; 
}


// 3. CMS
// ==========================================================================
.cms-area {
	letter-spacing: (20/1000) * 1rem;
	margin-bottom: 4rem;
	img {
		margin-bottom: 1.5rem;
	}
	@media (min-width: 1400px) {
		font-size: 1.8rem;
		line-height: (30/18);
		p, ul, ol {
			margin-bottom: 1em;
		}		
	}	
}