@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?8as8og') format('truetype'),
    url('../fonts/icomoon.woff?8as8og') format('woff'),
    url('../fonts/icomoon.svg?8as8og#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-pinterest:before {
  content: "\e900";
}
.svg-linkedin:before {
  content: "\e901";
}
.svg-twitter:before {
  content: "\e902";
}
.svg-facebook-official:before {
  content: "\e903";
}
.svg-window-restore:before {
  content: "\e904";
}
.svg-print:before {
  content: "\e905";
}
.svg-facebook:before {
  content: "\e906";
}
/* .svg-arrow-home:before {
  content: "\e907";
} */
/* .svg-blog-hover:before {
  content: "\e908";
} */
.svg-blog-nav-left:before {
  content: "\e909";
}
.svg-blog-nav-right:before {
  content: "\e90a";
}
.svg-bullet:before {
  content: "\e90b";
}
.svg-download:before {
  content: "\e90c";
}
.svg-exit:before {
  content: "\e90d";
}
.svg-user:before {
  content: "\e90e";
}
.svg-instagram:before {
  content: "\e90f";
}
.svg-youtube:before {
  content: "\e910";
}
