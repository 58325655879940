.video-container {
	&.active {
		.video-thumb {
			display: none;
		}
		.video-btn {
			display: none;
		}
	}
}
.video-thumb {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;	
}
.video-btn {
	border: none;
	padding: 0;
	background-color: #fff;    
	position: absolute;
	top: 50%;
	left: 50%;
	width: 8.6rem;
	height: 8.6rem;
	border-radius: 50%;
	color: $yellow;
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 7.2rem;
		height: 7.2rem;
		border: .1rem solid $yellow;
		border-radius: 50%;
		    -ms-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);		
	}
}