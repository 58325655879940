// ==========================================================================
// Soluciones
// ==========================================================================
//
// Table of Contents:
//
//  1. Headings & text
//  2. Masonry
//  3. Listado





// 1. Headings & text
// ==========================================================================
.h2-sol {
	letter-spacing: (100/1000) * 1rem;
}
.cms-sol {
	letter-spacing: (20/1000) * 1rem;
	margin-bottom: 4rem;
	@media (min-width: 993px) {
		font-size: 1.8rem;
		line-height: (30/18);
		p, ul, ol {
			margin-bottom: 3rem;
		}		
	}	
}


// 2. Masonry
// ==========================================================================
.sol-msnry {
	padding: 0 0.5rem;
	@media (min-width: 601px) {
		&:nth-child(2) { padding-top: 9rem; }
	}
}
.sol-msnry_title {
	font-size: 1.8rem;
	line-height: 1;
	letter-spacing: (100/1000) * 1rem;
	margin-top: 1rem;
	margin-bottom: 3.2rem;
	@media (min-width: 601px) {
		//font-size: 2rem;
	}
	@media (min-width: 993px) {
		font-size: 2rem;
	}
	@media (min-width: 1201px) {
		font-size: 2.4rem;
	}	
}


// 3. Listado
// ==========================================================================
.sol-list {
	line-height: (20/18);	
	padding-top: 6rem;
	margin-bottom: 0;
	strong { font-weight: 600; }
	li { margin-bottom: 2rem; }
	@media (min-width: 601px) {
		font-size: 1.8rem;
		-webkit-column-count:2;
		column-count: 2;
	    -webkit-column-gap: 4rem;
	    column-gap: 4rem;
	    -webkit-column-rule: 1px outset #e5e5e5;
	    column-rule: 1px outset #e5e5e5;
	    li { margin-bottom: 4rem; }
	}
	@media (min-width: 732px) {
		-webkit-column-count: 3;
		column-count: 3;
	}
	@media (min-width: 993px) {
		-webkit-column-count: 4;
		column-count: 4;
	}
}
.ver-mas{ 
	width: 100%;
}
