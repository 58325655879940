.tabs, .tabs-instalaciones {
  &.tabs-transparent {
    background-color: transparent;

    .tab a,
    .tab.disabled a,
    .tab.disabled a:hover {
      color: rgba(255,255,255,0.7);
    }

    .tab a:hover,
    .tab a.active {
      color: #fff;
    }

    .indicator {
      background-color: #fff;
    }
  }

  &.tabs-fixed-width {
    display: -ms-flexbox;
    display: flex;

    .tab {
      flex-grow: 1;
    }
  }

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 48px;
  width: 100%;
  background-color: $tabs-bg-color;
  margin: 0 auto;
  white-space: nowrap;

  .tab {
    display: inline-block;
    text-align: center;
    line-height: 48px;
    height: 48px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    a {
      &:hover,
      &.active {
        background-color: transparent;
        color: $tabs-text-color;
      }

      color: rgba($tabs-text-color, .7);
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 24px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: color .28s ease;
    }

    &.disabled a,
    &.disabled a:hover {
      color: rgba($tabs-text-color, .7);
      cursor: default;
    }
  }
  .indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $tabs-underline-color;
    will-change: left, right;
  }
}

// Fixed sideNav hide on smaller
@media #{$medium-and-down} {
  .tabs, .tabs-instalaciones {
    display: -ms-flexbox;
    display: flex;
    .tab {
      flex-grow: 1;
      a {
        padding: 0 12px;
      }
    }
  }
}







// X. Custom
// ==========================================================================
.tabs, .tabs-instalaciones {
  &.ul-group {
    width: auto;
    height: auto;
    margin-bottom: 6rem;    
    .tab {
      line-height: 1;
      height: auto;
      &:first-child {
        a { border-left: .1rem solid #000; }        
      }      
      a {
        color: #000;
        padding: 2.4rem 4rem;
        font-size: 1.6rem;
        letter-spacing: (150/1000) * 1rem;
        text-transform: uppercase;
        border-top: .1rem solid #000;
        border-right: .1rem solid #000;
        border-bottom: .1rem solid #000;
        &.active {
          background-color: transparent;
          color: #fff;
        }        
      }
    }
    .indicator {
      height: 100%;
      background-color: #000;
    }
    span {
      position: relative;
      z-index: 1;
    }   
  }
  @media (min-width: 993px) {
    &.ul-group {
      margin-top: 6rem;
      margin-bottom: 10rem;
    }
  }
}

.tabs-instalaciones{
  .tab{
    &.active
    {
      background-color: #000;
    }
  }
  @media (min-width: 993px) {
    &.ul-group {
      margin-top: 3rem;
    }
  }
}