// ==========================================================================
// Mosaico detalle
// ==========================================================================
//
// Table of Contents:
//
//  1. Encabezado
//  2. Slider
//  3. Textos
//  4. Bloques


.wrapper--mosaic {

  // 1. Encabezado
  // ==========================================================================
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    padding-bottom: .9rem;
    border-bottom: 2px solid #000;
    margin-bottom: 2rem;
    width: 100%;
    @media (min-width: 993px) {
      font-size: 6rem;
      line-height: 6rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  // 2. Slider
  // ==========================================================================
  .detail-col {
    max-width: 100%;
  }
  .slick-detail {
    max-width: 100%;
  }

  .cursor-zoom {
    display: block;
    width: 100%;
    cursor: url(../images/zoom.svg),auto;
  }
  .joint-image {
    .info-joint{
      position: absolute;
      bottom: 0;
      right: 0;
      padding: .3rem;
      background: rgba(255,255,255,.75);
      color: #000;
      font-weight: 300;
    }
  }
  .slick-img {
    &::before {
      padding-bottom: 155.6%;
      @media (min-width: 993px) {
        padding-bottom: 54.25%;
      }
    }
  }
  .tabs-mosaic-nav {
    margin-bottom: 3rem;
    @media (min-width: 993px) {
      margin-bottom: 7rem;
    }
    .tab {
      & + .tab {
        margin-left: 1.7rem;
      }
      .mosaic-slide {
        position: relative;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-decoration: none;
        cursor: pointer;
        .mosaic-img {
          width: 7rem;
          height: 7rem;
          position: relative;
          z-index: -1;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          margin-bottom: .9rem;
          @media (min-width: 993px) {
            width: 9rem;
            height: 9rem;
          }
        }
        .mosaic-title {
          position: relative;
          z-index: -1;
        }
        &.active {
          text-decoration: underline;
          font-weight: 500;
          .mosaic-img {
            border: 2px inset #000;
          }
        }
      }
    }
  }

  @media (max-width: 992.99px) {
    .detail-slide_col {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }

  // 3. Descripción
  // ==========================================================================
  &_description {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.4rem;
    display: block;
    @media (min-width: 993px) {
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 3.5rem;
    }
  }

  // 4. Bloques
  // ==========================================================================
  .wrapper--mosaic_block + .wrapper--mosaic_block {
    margin-top: 2rem;
  }
  &_block {
    padding: 1.4rem 2.8rem;
    background: $primary;
    font-size: 1.5rem;
    .header {
      cursor: pointer;
      &_title {
        font-weight: 500;
      }
      &_arrow {
        width: 15px;
        height: 15px;
        border-top: 2px solid #000;
        border-left: 2px solid #000;
        transform: rotate(225deg);
        position: relative;
        top: -3px;
      }
    }
    .body {
      display: none;
      label, .label {
        display: block;
        width: 100%;
        padding-bottom: .6rem;
        margin-bottom: 1.4rem;
        &.underline {
          border-bottom: 1px solid #000;
        }
      }
      .select {
        position: relative;
        select {
          display: block;
          width: 100%;
          background: transparent;
          border: 0;
          border-bottom: 1px solid #000;
          font-size: 2rem;
          line-height: 1;
          height: 3.8rem;
          padding-left: 0;
          padding-right: 1.4rem;
          appearance: none;
        }
        #packaging {
          min-width: 165px;
        }
        &:before {
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          transform: rotate(225deg);
          top: .7rem;
          right: 0;
          pointer-events: none;
        }
      }
      .input-group.underline {
        border-bottom: 1px solid #000;
        min-height: 3.8rem;
      }
      [type="number"] {
        border: 0;
        margin-bottom: 0;
        height: auto;
        font-size: 2rem;
        + span {
          font-size: 2rem;
        }
      }
      .resultado-consulta {
        font-size: 2rem;
        display: none;
      }
      .value {
        font-size: 2rem;
        + .label {
          margin-top: 1.9rem;
        }
      }
      &.body-packaging {
        flex-wrap: wrap;
        margin-left: -1.6rem;
        margin-right: -1.6rem;
        .col {
          margin-bottom: 3rem;
          flex: 0 0 25%;
          max-width: 25%;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
          @media (max-width: 992.99px) {
            flex: 0 0 33.333%;
            max-width: 333.333%;
            &.col-formato {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    &.active {
      padding-bottom: 2.5rem;
      .header {
        padding-bottom: 1.9rem;
        &_arrow{
          transform: rotate(45deg);
          top: 6px;
        }
      }
      .body {
        display: flex;
      }
    }
    .stock-validation {
      width: 100%;
      margin-top: 1rem;
      background-color: #ffead7;
      border: 1px solid #ffe2c7;
      color: #8d5724;
      padding: 1rem 1.5rem;
      display: none;
    }
    form {
      .container {
        padding-right: 0;
        padding-left: 0;
        .fields-budget {
          display: none;
        }
        .checkbox-c {
          margin-bottom: 1.6rem;
        }
        .first-contact-row {
          margin-top: 7rem;
        }
        .input-field {
          label {
            text-transform: none;
          }
          &.has-error {
            position: relative;
            .invalid-feedback {
              position: absolute;
              bottom: 0.2rem;
            }
          }
        }
        .select-wrapper {
          input {
            &.select-dropdown {
              text-transform: none;
            }
          }
          &.has-error {
            position: relative;
            .invalid-feedback {
              position: absolute;
              bottom: -2.4rem;
            }
          }
        }
        .dropdown-content {
         text-transform: none;
          li {
            > a, > span {
              font-weight: 400;
            }
          }
        }
        .input-cantidad {
          &.has-error {
            position: relative;
            input {
              border-bottom: 1px solid rgb(220, 53, 69)!important;
            }
            .invalid-feedback {
              position: absolute;
              bottom: -3rem;
            }
          }
        }
      }
      .form-checks-button {
        flex-wrap: wrap;
        .recaptcha-label {
          flex: 0 0 100%;
          & + .checkbox-c {
            flex: 0 0 100%;
            & + div {
              flex: 0 0 100%;
            }
          }
        }
      }
    }
  }
}
