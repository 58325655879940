// ==========================================================================
// Ezarri - ¿Por qué Ezarri?
// ==========================================================================
//
// Table of Contents:
//
//  1. Texto
//  2. Encabezados
//  3. Vídeo
//  4. Bloques
//  5. Ul
//  6. Joint
//  7. Jumbotron
//  8. Items



// 1. Texto
// ==========================================================================
.cms-por {
	font-size: 1.6rem;
	line-height: (24/20);
	@media (min-width: 601px) {
		font-size: 1.8rem;
	}
	@media (min-width: 993px) {
		font-size: 2rem;
	}
}
.cms-por-l {
	@media (min-width: 993px) {
		padding-right: 4rem;
	}
}
.cms-por-r {
	@media (min-width: 993px) {
		padding-left: 4rem;
	}
}


// 2. Encabezados
// ==========================================================================
.h2-por {
	font-size: 2.2rem;
	letter-spacing: (100/1000) * 1rem;
	text-transform: uppercase;
	@media (min-width: 601px) {
		font-size: 3.3rem;
	}
	@media (min-width: 993px) {
		font-size: 4.4rem;
	}	
}

//  3. Vídeo
// ==========================================================================
.video-por {
	margin-bottom: 3rem;
	@media (min-width: 601px) {
		margin-bottom: 4.5rem;
	}
	@media (min-width: 993px) {
		margin-bottom: 6rem;
	}	
}

//  4. Bloques
// ==========================================================================
.por-c {
	padding-top: 3rem;
	padding-bottom: 3rem;
	&:last-child { padding-bottom: 0; }
	@media (min-width: 601px) {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}
	@media (min-width: 993px) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}	
}

// 5. Ul
// ==========================================================================
.por-ul {
	margin: 4rem 0 0 0;
	li {
		margin-bottom: 1.2rem;
	}
}

// 6. Joint
// ==========================================================================
.joint-c {
	margin-top: 1.2rem;
}

// 7. Jumbotron
// ==========================================================================
.jumbotron {
	padding: 12rem 3rem;
	background: $primary;
	font-weight: 500;
	text-align: center;
	margin-bottom: 3.5rem;
	font-size: 6rem;
	line-height: 1.1;
	@media(min-width: 1920px) {
		font-size: 13rem;
	}
	@media(min-width: 2000px) {
		padding-top: 8.5rem;
		padding-bottom: 8.5rem;
		font-size: 20rem;
		line-height: 16rem;
		margin-bottom: 8.5rem;
	}
}

// 8. Items
// ==========================================================================
.porque-items {
	.item {
		margin-bottom: 5rem;
		@media (min-width: 993px) {
			margin-bottom: 18rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
		@media (min-width: 993px) {
			&:nth-child(5) {
				margin-bottom: 0;
			}
		}
		.number {
			font-weight: 500;
			font-size: 2rem;
			line-height: 2rem;
			display: inline-block;
			width: auto;
			@media(min-width: 993px) {
				display: block;
				width: 100%;
				font-size: 12rem;
				line-height: 12rem;
				margin-bottom: 1rem;
			}
		}
		h2 {
			font-weight: 500;
			font-size: 2rem;
			line-height: 2rem;
			margin-bottom: 2.3rem;
			display: inline-block;
			width: auto;
			@media(min-width: 993px) {
				display: block;
				width: 100%;
				font-size: 8rem;
				line-height: 8rem;
				margin-bottom: 4.4rem;
			}
		}
		p {
			margin-bottom: 0;
			font-size: 1.5rem;
			@media (min-width: 993px) {
				font-size: 2rem;
			}
		}
	}
}