// ==========================================================================
// Generador
// ==========================================================================
//
// Table of Contents:
//
//  1. X
//  2. Modal
//	3. Headings
//	4. Selector
//	5. Botones
//	6. Como generar una mezcla
//	7. Juntas
//	8. Options
//	9. Modal presupuesto
//	10. Modal pared virtual
//	11. Muestra
//	12. Tabs


// 1. Listado principal modal
// ==========================================================================
.tabs.gen-tabs {
	height: auto;
	padding-bottom: 1rem;
	border-bottom: .1rem solid #000;
	.tab {
		a {
			overflow: visible;
			color: #000;
			font-weight: 700;
			//&.active, &:hover { color: $primary }
		}
	}
	.indicator {
		height: auto;
		background-color: transparent;
		bottom: -.1rem;
		&:before, &:after {
			bottom: 100%;
		    left: 50%;
		    border: solid transparent;
		    content: " ";
		    height: 0;
		    width: 0;
		    position: absolute;
		    pointer-events: none;
		    display: block;			
		}
		&:before {
		    border-color: rgba(194, 225, 245, 0);
		    border-bottom-color: #000;
		    border-width: 10px;
		    margin-left: -10px;   
		}
		&:after {
		    border-color: rgba(136, 183, 213, 0);
		    border-bottom-color: #fff;
		    border-width: 9px;
		    margin-left: -9px;  
		}
	}
	@media (min-width: 992px) {
		padding-bottom: 2rem;
		.tab {
			a {
				font-size: 2rem;
			}
		}
		.indicator { 
			&:before {
			    border-width: 20px;
			    margin-left: -20px;   
			}
			&:after {
			    border-width: 19px;
			    margin-left: -19px;  
			}
		}
	}
}


// 2. Modal
// ==========================================================================
.relative {
	position: relative;
}
.gen-modal {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 6;
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	margin-top: 4rem;
	padding: 4rem 3rem;
	&:before {
		bottom: 100%;
		left: 0;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255,255, 255, 0);
		border-bottom-color: #fff;
		border-width: 6px;
		margin-left: 44px;
	}
	@media (min-width: 601px) and (max-width: 993px) {
		&:before {
			left: 8.3333333333%;
		}
	}
}
.gen-modal_content {
	margin-left: -3rem;
	margin-bottom: 0;
	font-size: 1.4rem;
	li {
		padding-left: 3rem;
		width: 50%;
		margin-bottom: 2.3rem;
	}
	a {
		color: #333;
	}
	span {
		display: inline-block;
    padding: .5rem 0;
    &.new {
      position: absolute;
      display: -ms-flexbox;
      display: flex;
			align-items: center;
      background-color: $primary;
      padding: 5px;
    }
	}
	@media (min-width: 568px) {
		li {
			width:(100/3) * 1%;
		}
	}
	@media (min-width: 667px) {
		li {
			width: 25%;
		}
	}
	@media (min-width: 993px) {
		li {
			width: (100/6) * 1%;
		}
	}	
	@media (min-width: 1201px) {
		li {
			width: 12.5%;
		}
	}
}
.gen-modal_content-old {
  padding-top: 3rem;
  h3 {
    letter-spacing: .1rem;
    font-size: 3.2rem;
  }
  .img-landing {
    margin: 0 auto 16px auto;
  }
}

// 3. Headings
// ==========================================================================
.h2-gen {
	font-size: 2rem;
	letter-spacing: (100/1000) * 1rem;
	margin-bottom: 3rem;
	&:after {
		content: '';
		display: block;
		width: 3rem;
		height: .3rem;
		background-color: #000;
		margin-top: .9rem;
	}
	@media (min-width: 601px) {
		font-size: 2.4rem;
	}
}

// 4. Selector
// ==========================================================================
.gen-c {
	-ms-flex-direction: column;
	-moz-flex-direction: column;
	flex-direction: column;
	margin-bottom: 2rem;
	@media (min-width: 601px) {
		-ms-flex-direction: row;
		-moz-flex-direction: row;
		flex-direction: row;
	}
}
.gen-c_info {
	input {
		margin-bottom: 0 !important;
		height: auto !important;
	} 
}
.gen-c_content {
	-ms-flex-direction: column;
	-moz-flex-direction: column;
	flex-direction: column;
	width: 10rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.gen-c_name {
	font-size: 1.8rem;
	line-height: (20/18);
}
.gen-c_range {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	@media (max-width: 600px) {
		padding-top: 1rem;
	}
}



// 5. Botones
// ==========================================================================
.gen-percentage-r {
	font-size: 1.5rem;
	color: #8a6d3b;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	@media (max-width: 600px) {
		margin-bottom: 2rem;
	}
}


// 6. Como generar una mezcla
// ==========================================================================
.gen-warn {
	background-color: #fcf8e3;
	border: .1rem solid #faebcc;
	border-radius: .4rem;
	color: #8a6d3b;
	padding: 3.9rem 2.6rem;
	position: relative;
	margin-top: 6.8rem;
	ol {
		list-style: none;
		counter-reset: section;
	}
	li {
		position: relative;
		margin-bottom: 1.4rem;
		padding-left: 2rem;
		&:before {
			position: absolute;
			left: 0;
			counter-increment: section;
			content: counter(section) ". ";
			color: #999;
		}			
	}
}
.gen-warn_title {
	font-size: 1.8rem;
	margin-bottom: 1.5rem;
}
.gen-warn_close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
}


// 7. Juntas
// ==========================================================================
.gen-juntas {
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	font-size: 1.3rem;
	max-width: 300px;
	li {
		display: inline-block;
		width: 50%;
		//height: 4rem;
		position: relative;
		margin-bottom: 1rem;
		&.active {
			button {
				border-color: $primary;
			}
		}
		&.item-white { color: #fff; }
	}
	button {
		display: block;
		width: 100%;
		text-align: right;
		text-transform: uppercase;
		padding: 0 .8rem;
		border: 2px solid transparent;
		text-shadow: 1px 1px 1px rgba(0,0,0,1);
	}
}


// 8. Options
// ==========================================================================
.gen-mix-opt {
	-ms-flex-direction: column-reverse;
	-moz-flex-direction: column-reverse;
	flex-direction: column-reverse;
	text-align: center;
	margin-bottom: 2rem;
	@media (min-width: 601px) {
		-ms-flex-direction: row;
		-moz-flex-direction: row;
		flex-direction: row;
		align-items: center;
	}
	@media (min-width: 993px) {
		margin-bottom: 0;
	}
}

.gen-result {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.gen-result-opt {
	-ms-flex-pack: distribute;
	justify-content: space-around;
	padding-top: 1.7rem;
	margin-bottom: 2.4rem;
}
.gen-icon {
	color: #000;
	span {
		padding-left: 1rem;
	}	
}


// 9. Modal presupuesto
// ==========================================================================
.presu {
	background-color: #f8f8f8;
	padding: 3rem 1rem;
	margin-bottom: 6rem;
	.gen-c_info {
		margin-bottom: 1.5rem;
	}

}
.presu-row {
	padding: 0 2rem;
}
.presu-row1 {
	margin-bottom: 2rem;
	border-bottom: .1rem solid #e0e0e0;
}
.presu-row2 {
	-ms-flex-direction: column;
	-moz-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	span { margin-bottom: 1rem; }
	@media (min-width: 601px) {
		-ms-flex-direction: row-reverse;
		-moz-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-ms-flex-pack: end;
		justify-content: flex-end;
		align-items: center;		
		span {
			margin-bottom: 0;
			margin-left: 1.5rem;			
		}
	}
}
.presu_junta {
	width: 190px;
	height: 40px;
}


// 10. Modal pared virtual
// ==========================================================================
.vw-mb {
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
}

// 11. Muestra
// ==========================================================================
#juntas {
	@media (max-width: 1200px) {
		margin-top: 2rem;
	}
}
.sample-grid {
	padding: .15rem;
	max-width: 539px;
	margin: 0 auto;
	&:after { 
		content: '';
		display: block; 
		height: 0; 
		clear: both;
	}	
}
.sample-grid_col {
	float: left;
	width: 10%;
	padding: .15rem;
}


.celda {
	width: 9%;
	margin: 1% 0% 0% 1%;
	font-size: 0;
    line-height: 0;
	display: block;
	float: left;
}
.celda2 {
	width: 10px;
	margin: 2px 0 0 2px;
	font-size: 0px;
    line-height: 0px;
	display: block;
	float: left;
}

.gen-modal_delete {
    color: #6c6f70;
    position: absolute;
    bottom: 9px;
    right: 3rem;
    z-index: 1;
    display: block;
    i {
		display: block;
		font-size: 1.8rem;
    }
}

// 12. Tabs
// ==========================================================================
.generator-tabs { 
  overflow-x: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #000;
  }
  li {
    width: 50%;
  }
  .tab {
    a {
      font-weight: 700;
      color: #333;
      &:hover {
        color: #333;
      }
      &.active {
        background-color: $primary;
        color: #000;
      }
    }
  }
  .indicator {
    display: none;
  }
  @media (min-width: 992px) {
    li {
      width: 20%;
    }    
    .tab {
      a {
        font-size: 2.2rem;
      }
    }
  }
}
.generator-content {
  padding-top: 15px;
}

// X. Iframe nuevo generador + funcionamiento descripción nueva en movil
// ==========================================================================
.iframe-generator {
	min-height: 930px;
}

.generator-intro-mobile {
	padding: 1.4rem 2.8rem;
	background: $primary;
	font-size: 1.5rem;
	margin-bottom: 3rem;
	.header {
		cursor: pointer;
		&_title {
			font-weight: 500;
		}
		&_arrow {
			width: 15px;
			height: 15px;
			border-top: 2px solid #000;
			border-left: 2px solid #000;
			transform: rotate(225deg);
			position: relative;
			top: -3px;
		}
	}
	.body {
		display: none;
	}
	&.active {
		padding-bottom: 2.5rem;
		.header {
			padding-bottom: 1.9rem;
			&_arrow{
				transform: rotate(45deg);
				top: 6px;
			}
		}
		.body {
			display: flex;
		}
	}
}

