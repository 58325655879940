// ==========================================================================
// Ezarri - Nuestra trayectoria
// ==========================================================================
//
// Table of Contents:
//
//  1. Texto
//  2. Vídeo



// 1. Texto
// ==========================================================================
.our-intro {
	font-size: 1.8rem;
	letter-spacing: (125/1000) * 1rem;
	padding-top: 6rem;
	margin-bottom: 0;
	@media (min-width: 601px) {
		font-size: 2rem;
	}	
	@media (min-width: 993px) {
		font-size: 2.8rem;
	}
}
.our-title {
	font-size: 2rem;
	line-height: (36/30);
	font-weight: 600;
	margin-bottom: 1.5rem;
	&:after {
		content: '';
		display: block;
		width: 3rem;
		height: .3rem;
		background-color: #000;
		margin-top: 1rem;
	}
	@media (min-width: 601px) {
		font-size: 2.4rem;
		margin-bottom: 2rem;
	}	
	@media (min-width: 993px) {
		font-size: 3rem;
	}	
}
.our-c {
	position: relative;
	padding: 0;
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		left: 0%;
		width: .2rem;
		height: 1.5rem;
		background: $primary; /* Old browsers */		
	}
	&:before {
		top: 0; /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(234,246,254,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,1)),to(rgba(234,246,254,1)));
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(234,246,254,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(234,246,254,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='$primary',GradientType=0 ); /* IE6-9 */		
	}
	&:after { /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(234,246,254,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(234,246,254,1)),to(rgba(255,255,255,1)));
		background: -o-linear-gradient(top, rgba(234,246,254,1) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom, rgba(234,246,254,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */		
	}
	.cms { margin-top: 1.5rem; }
	@media (min-width: 601px) {
		padding: 3rem 0;
		&:before, &:after {
			height: 3rem;
		}
	}	
	@media (min-width: 993px) {
		padding: 5rem 0;
		&:before, &:after {
			left: 50%;
			margin-left: -.1rem;
			height: 5rem;
		}
	}
}
.our-row {
	position: relative;
	padding: 1.5rem 0;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 2px;
		background-color: $primary;
	}	
	@media (min-width: 993px) {
		display: -ms-flexbox;
		display: flex;
		        align-items: center;
		padding: 3rem 0;
		&:before {
			left: 50%;
			margin-left: -1px;
		}		
		&:nth-child(odd) {
			-ms-flex-direction: row-reverse;
			-moz-flex-direction: row-reverse;
			flex-direction: row-reverse;
			.our-item {
				padding-right: 3rem;
			}
			.our-year {
				padding-left: 3rem;
				text-align: left;
				&:before {
					margin-left: -36px;
				}
			}
		}
		&:nth-child(even) {
			.our-item {
				padding-left: 3rem;
			}
			.our-year {
				position: relative;
				-ms-flex-direction: row-reverse;
				-moz-flex-direction: row-reverse;
				flex-direction: row-reverse;
				padding-right: 3rem;
				text-align: right;
				&:before {
					right: -6px;
				}				
			}			
		}		
	}
}
.our-year {
	font-weight: 700;
	font-size: 3rem;
	color: #e1e1e1;
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: $primary;
	}
	@media (max-width: 992px) {
		padding-left: 1.5rem;
		&:before {
			left: -5px;
		}
	}	
	@media (min-width: 601px) {
		font-size: 5rem;
	}	
	@media (min-width: 993px) {
		font-size: 10rem;
	}	
}
.our-item {
	@media (max-width: 992px) {
		padding-left: 1.5rem;
	}
}

// 2. Vídeo
// ==========================================================================
.video-profile {
	@media (max-width: 700px){
		&.video-container {
			padding-bottom: 150%;
		}
	}
	.info {
		width: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 100;
		transform: translate(-50%,-50%);
		color: #FFFFFF;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 50%;
		img {
			filter: invert(100%);
			display: block;
			max-width: 100%;
			height: auto;
		}
		.video-btn {
			background-color: transparent;
			position: relative;
			top: unset;
			left: unset;
			width: auto;
			height: auto;
			border-radius: 0;
			color: #FFFFFF;
			transform: none;
			display: block;
			&:after {
				display: none;
			}
			span {
				width: 0;
				height: 0;
				border-top: 3.3rem solid transparent;
				border-bottom: 3.3rem solid transparent;
				border-left: 6.6rem solid #FFFFFF;
				display: inline-block;
			}
		}
		.title {
			font-size: 3rem;
			font-weight: 500;
		}
	}
	&.active {
		.info {
			display: none;
		}
	}
}