.breadcrumb {
	font-size: 1rem;
	list-style: none;
	text-transform: uppercase;
	color: #AAA;
	font-weight: 500;
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
	a {
		color: #333;
	}
	li {
		&:after {
			content: '';
			width: 3rem;
			height: .1rem;
			background-color: #000;
			margin-left: .5rem;
			margin-right: .5rem;
		}
		&:last-child {
			&:after { content: none; }
		}
	}
	@media (min-width: 1400px) {
		font-size: 1.4rem;
		margin-top: 2rem;
		margin-bottom: 2rem;		
	}
}