.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0;
    }
}
.embed-responsive-1by1 {
    padding-bottom: 100%;
}
.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}
.embed-responsive-3by2 {
    padding-bottom: (2/3) * 100%;
}
.embed-responsive-21by9 {
    padding-bottom: (9/21) * 100%;
}

@media (min-width: 601px) {
    .embed-responsive-1by1 {
        padding-bottom: 100%;
    }
    .embed-responsive-md-16by9 {
        padding-bottom: 56.25%;
    }
    .embed-responsive-md-3by2 {
        padding-bottom: (2/3) * 100%;
    }
    .embed-responsive-md-21by9 {
        padding-bottom: (9/21) * 100%;
    }
}