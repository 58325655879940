// ==========================================================================
// Ezarri - Internacionalización
// ==========================================================================
//
// Table of Contents:
//
//  1. Textos



// 1. Textos
// ==========================================================================
h1.h1-interna {
	font-size: 4rem;
	line-height: 4rem;
  	margin-bottom: 2.9rem;
	padding-bottom: .9rem;
	border-bottom: 2px solid #000;
	@media (min-width: 993px) {
		margin-bottom: 6rem;
		padding-bottom: 0;
		border: 0;
	}
}
.intro-interna {
	font-size: 1.5rem;
	line-height: 2rem;
	margin-bottom: 5rem;
	p {
		margin-bottom: 2rem;
	}
	@media (min-width: 993px) {
		font-size: 2rem;
		line-height: 3rem;
	}
}

.internalizacion{
	position: relative;
	@media (min-width: 993px) {
		text-align: center;
	}
	h2{
		font-size: 2.5rem;
		line-height: 2.5rem;
		margin-bottom: 2rem;
		@media (min-width: 993px) {
			font-size: 4rem;
			line-height: 4rem;
			margin-bottom: 3rem;
		}
	}
	.video{
		max-width: 1000px;
		margin-bottom: 4rem;
		@media (min-width: 993px) {
			margin: 2rem auto 8rem auto;
		}
		@media (max-width: 992.99px) {
			margin-left: -3rem;
			margin-right: -3rem;
			width: calc(100% + 6rem) !important;
		}
	}
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
	.slider-internalizacion {
	  display: block;
	  margin: 0 auto 3rem auto;
	  position: relative;
	  text-align: center;
	  .slick-slide {
	  	text-align: center;
	    overflow: hidden;
	    font-size: 25px;
	    font-weight: 800;
	    p{
			font-size: 25px;
			font-weight: 500;
			letter-spacing: 1px;
			text-align: center;
			margin-bottom: 0;
		}
	  }
	}
	svg{
		max-width: 1200px;
		width: 100%;
	}
	path {
		fill: $primary;
		stroke: white;
	}
	path {
		transition: fill 4s ease;
	}
	.selected {
		fill: #000;
	}
	.destacados{
		h3 {
			font-size: 2.5rem;
			line-height: 2.5rem;
			margin-bottom: 4rem;
			@media (min-width: 993px) {
				font-size: 4rem;
				line-height: 4rem;
				margin-bottom: 8rem;
			}
		}
		.item-country {
			width: 100%;
			text-align: left;
			font-size: 1.5rem;
			@media (min-width: 993px) {
				font-size: 2rem;
			}
			&:not(:last-of-type){
				margin-bottom: 2.5rem;
				@media (min-width: 993px) {
					margin-bottom: 6rem;
				}
			}
			h4 {
				font-size: 1.5rem;
				text-transform: uppercase;
				border-bottom: 1px solid #000;
				margin-bottom: 1rem;
				padding-bottom: .2rem;
				@media (min-width: 993px) {
					font-size: 2rem;
				}
			}
		}
	}
}