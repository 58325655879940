// ==========================================================================
// Dropdown
// ==========================================================================
//
// Table of Contents:
//
//  1. Dropdown button
//  2. x
//  3. x



// 1. Dropdown button
// ==========================================================================

.dropdown-button-default {
  -ms-flex-pack: justify;
  justify-content: space-between;
	color: #000;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: (190/1000) * 1rem;
	text-transform: uppercase;
	padding-top: .8rem;
	padding-bottom: .8rem;
	border-bottom: .1rem solid #000;
	.more {
		position: relative;
		width: 12px;
		height: 12px;
		&:before, &:after {
			content: '';
			position: absolute;
			display: block;
			background-color: #000;
		}
		&:before {
			width: 12px;
			height: 2px;
			top: 5px;
		}		
		&:after {
			width: 2px;
			height: 12px;
			left: 5px;
		}
	}
	&.active {
		.more {
			&:after { display: none; }
		}
	}
}