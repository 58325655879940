.dropdown-content {
  @extend .z-depth-1;
  background-color: $dropdown-bg-color;
  margin: 0;
  display: none;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  z-index: 999;
  will-change: width, height;
  border: .1rem solid #000;
  text-transform: uppercase;
  li {
    clear: both;
    color: $off-black;
    cursor: pointer;
    width: 100%;
    text-align: left;
    &:first-child

    &:hover, &.active, &.selected {
      background-color: $dropdown-hover-bg-color;
    }

    &.active.selected {
      background-color: darken($dropdown-hover-bg-color, 5%);
    }

    &.divider {
      min-height: 0;
      height: 1px;
    }

    & > a, & > span {
      font-size: 1.2rem;
      font-weight: 600;
      color: #000;
      display: block;
      line-height: 1;
      padding: .9rem 1.9rem;
      letter-spacing: (190/1000) * 1rem;
    }

    & > span > label {
      top: 1px;
      left: 0;
      height: 18px;
    }

    // Icon alignment override
    & > a > i {
      height: inherit;
      line-height: inherit;
      float: left;
      margin: 0 24px 0 0;
      width: 24px;
    }
  }
}

// Input field specificity bugfix
.input-field.col .dropdown-content [type="checkbox"] + label {
  top: 1px;
  left: 0;
  height: 18px;
}

