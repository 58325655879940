// ==========================================================================
// generador-print.php
// ==========================================================================
//
// Table of Contents:
//
//  1. @media
//  2. Head
//  3. Sample row
//  4. Junta
//  5. Sample
//  6. Big sample



// 1. media
// ==========================================================================

@media only screen {
	.print {
		display: none;
	}
}
@media only print {
	body {
		width: 210mm;
		height: 297mm;
	}
	@page {
	  size: A4;
	}
	.page {
		page-break-after: always;
	}


	// 2. Head
	// ==========================================================================

	.print-head {
		margin-top: 10mm;
		margin-bottom: 5mm;
	}
	.print-logo {
		width: 42mm;
	}
	.print-aside {
		float: left;
		width: 7mm;
		height: 211mm;
	}
	.print-content {
		width: 120mm;
		margin-left: 20mm;
		float: left;
		height: 10mm;
	}


	// 3. Sample row
	// ==========================================================================

	.print-row { 
		border-bottom: 0.265mm solid #e0e0e0;
		padding: .8mm 0;
	}
	.print-row:first-child {
		border-top: 0.265mm solid #e0e0e0;
		margin-top: 5mm;
	}	
	.print-title {
		width: 68mm;
		padding-left: 5mm;
		text-transform: uppercase;
	}
	.print-percentage {
		width: 40mm;
	}
	.print-sample {
		width: 12mm;
		height: 12mm;
	}


	// 4. Junta
	// ==========================================================================

	.print-junta {
		margin-top: 20mm;
		margin-bottom: 20mm;
	}
	.print-junta_color {
		padding-right: 6mm;
	}
	.print-junta_code {
		padding-right: 4mm;
	}
	.print-junta_img {
		position: relative;
		width: 42mm;
		height: 4mm;
		overflow: hidden;
	}
	.print-junta_img img {
		position: absolute;
		top: 0;
		left: 0;	
	}


	// 5. Sample
	// ==========================================================================

	.sample-grid--print {
		width: 85mm;
		position: relative;
		padding: .4mm;
		overflow: hidden;
	}
	.sample-grid_col {
	    float: left;
	    width: 10%;
	    padding: .15rem;
	}		
	.sample-grid_item {
		top: .4mm;
		right: .4mm;
		bottom: .4mm;
		left: .4mm;
	}
	.sample-grid_bg {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;		
	}


	// 6. Big sample
	// ==========================================================================

	#pared {
		-webkit-print-color-adjust: exact;
	}		
	.print-bigsample {
		margin-top: 20mm;
	}
}