// ==========================================================================
// Tanñas
// ==========================================================================
//
// Table of Contents:
//
//  1. Área técnica - Materiales
//  2. Responsive


// 1. Área técnica - Materiales
// ==========================================================================
.table-default {
	line-height: (20/18);
	thead {
		background-color: $primary;
		font-size: 1.6rem;
		letter-spacing: (100/1000) * 1rem;
		th {
			&.center-align {
				text-align: left;
			}
		}
	}
	tr {
		&:last-child {
			border-bottom: none;
		}
	}
	th, td {
		border-radius: 0;
		padding: 2rem 1.5rem;
		border-right: .1rem solid #dcdcdc;
		&:last-child { border-right: none; }
	}
	th {
		padding-top: 1.7rem;
		padding-bottom: 1.7rem;
	}
	@media (min-width: 993px) {
		font-size: 1.8rem;
		thead {
			font-size: 2.4rem;
			th {
				&.center-align {
					text-align: center;
				}
			}			
		}			
	}
}
.table-grey {
	background-color: #f4f2f4;
}
.table-green {
	background-color: $primary;
}

table.highlight.table-default>tbody>tr.table-green:hover, table.striped>tbody>tr.table-green:nth-child(odd) {
	background-color: $primary;
}
.center-align {
	td, th {
		text-align: center;
	}
}


// 2. Responsive
// ==========================================================================
.table-responsive {
	overflow-x: auto;
	margin-bottom: 6rem;
}