// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

/**
 * Basic styles for links
 */
a {
  color: #000;
  font-weight: 500;
  text-decoration: none;

  @include on-event {
    //color: $text-color;
    text-decoration: underline;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}
ul, ol {
  margin-top: 0;
  padding-left: 0;
}

address {
  font-style: normal;
}

.wrapper {
  padding-top: 9.1rem;
  @media (min-width: 1201px) {
    padding-top: 14.5rem;
  } 
}

.body-home {
  .wrapper {
    padding-top: 7.6rem;
    @media (min-width: 1201px) {
      padding-top: 12rem;
    }
  }

  .row:not(.d-flex) {
    display: block;
  }

  .btn-reset {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}