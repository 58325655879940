.sitemap { 
	margin-left: -.6rem;
	margin-right: -.6rem;
	a {
		color: #000;
	}
	h2 {
		font-size: 1.8rem;
		@media (min-width: 601px) {
			font-size: 2rem;
		}
	}
	h3 {
		font-size: 1.6rem;
		@media (min-width: 601px) {
			font-size: 1.8rem;
		}		
	}
	h2, h3 { margin-bottom: 0; }
}
.sitemap_li {
	margin-bottom: 2rem;
	padding-right: .6rem;
	padding-left: .6rem;
	flex-basis: 50%;
	@media (min-width: 568px) {
		flex-basis: 50%;
	}
	@media (min-width: 667px) {
		flex-basis: (100/3) * 1%;
	}
	@media (min-width: 993px) {
		flex-basis: 20%;
	}		
}
.sitemap_ul {
	margin-top: 1.5rem;
	li {
		margin-bottom: 1.5rem;
		&:last-child { margin-bottom: 0; }
	}
}