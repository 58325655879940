body.active {
  overflow: hidden;
}
#search-wrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: .3s;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    display: block;
  }
  input {
    height: auto;
    width: 70%;
    border: transparent;
    background: rgba(0,0,0,0);
    border-bottom: solid 1px #000;
    color: #686868;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 45px;
    text-align: center;
    padding-bottom: 10px;
    overflow: hidden    
  }
}
#js-search-close {
  width: auto;
  position: absolute;
  float: right;
  right: 4vw;
  top: 57px;
  transition: .3s ease;
}
@media only screen and (max-width: 768px) {
  #js-search-close {
    float:right;
    right: 29px;
    top: 38px
  }
}
@media only screen and (max-width: 768px) {
  #search-wrap input {
    font-size:18px
  }
}
.desktop-search-icon i {
  font-size: 18px;
  @media only screen and (min-width: 1400px) {
    font-size: 21px
  }
}
#search-wrap {
  input::-webkit-input-placeholder {
    color: #686868;
    position: relative;
    top: 20px;
    opacity: 0;
    transition: .3s;
    transition-delay: .2s
  }
  input::-moz-placeholder {
    color: #686868;
    position: relative;
    top: 20px;
    opacity: 0;
    transition: .3s;
    transition-delay: .2s
  }
  input:-ms-input-placeholder {
    color: #686868;
    position: relative;
    top: 20px;
    opacity: 0;
    transition: .3s;
    transition-delay: .2s
  }
  input:-moz-placeholder {
    color: #686868;
    position: relative;
    top: 20px;
    opacity: 0;
    transition: .3s;
    transition-delay: .2s
  }
  &.active {
    input::-webkit-input-placeholder {
      color: #686868;
      position: relative;
      top: 0;
      opacity: 1
    }
    input::-moz-placeholder {
      color: #686868;
      position: relative;
      top: 0;
      opacity: 1
    }
    input:-ms-input-placeholder {
      color: #686868;
      position: relative;
      top: 0;
      opacity: 1
    }
    input:-moz-placeholder {
      color: #686868;
      position: relative;
      top: 0;
      opacity: 1
    }
  }  
}


.mobile-search-icon {
  position: absolute;
  right: 1.5rem;
  height: 24px;
  @media only screen and (min-width: 1201px) {
    display: none;
  }
}

.search-results {
  margin-top: 20px;
  a {
    color: #000;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    b { font-weight: 500; }
  }
}
.search-result {
  margin-bottom: 26px;
}
.search-url {
  color: $primary;
}

// Nuevo buscador Google
// ==========================================================================
#searcher-container {
  .no-results {
    color: red;
  }
  .info-results {
    margin-bottom: 3rem;
  }

  #results {
    li {
      margin-bottom: 2rem;
    }
  }
}