.c-product {
	h3, h4, h5 {
		margin-bottom: 0;
	}
	h3 {
		font-size: 1.5rem;
	}
	h5 {
		font-size: 1.6rem;
	}
	.collapsible-header {
		justify-content: space-between;
		i {
			margin-right: 0;
			width: auto;
		}
	}
	.collapsible-header.active i { transform: rotate(180deg); }
}
.c-product_download {
	i {
		margin-left: 1rem;
	}
}