.modal.modal-c {
	width: calc(100% - 3rem);
	background-color: transparent;
	border-radius: 0;
	        box-shadow: none;
	@media (min-width: 601px) {
		width: calc(100% - 12rem);
	}
	@media (min-width: 993px) {
		width: calc(100% - 36rem);
	}
}
.modal-c_container {
	position: relative;
	background-color: $primary;
	padding-top: 4rem;
	padding-bottom: 4rem;
	@media(max-width: 992.99px) {
		.form-checks-button {
			flex-wrap: wrap;
			.recaptcha-label {
				flex: 0 0 100%;
				& + .checkbox-c {
					flex: 0 0 100%;
					& + div {
						flex: 0 0 100%;
					}
				}
			}
		}
	}
}
.catalog-title {
	display: block;
	line-height: 1;
	font-size: 1.5rem;
	margin-bottom: 2.3rem;
	@media(min-width: 993px) {
		margin-bottom: .5rem;
		font-size: 2rem;
		&.not-subtitle {
			margin-bottom: 3.5rem;
		}
	}
}
.catalog-subtitle {
	display: block;
	font-size: 1.5rem;
	line-height: 2rem;
	margin-bottom: 3.5rem;
}
.modal-c_close {
	position: absolute;
	top: 3.4rem;
	right: 3.4rem;
	background-color: transparent;
	border: none;
	padding: 0;
	width: 1.2rem;
	height: 1.2rem;
	cursor: pointer;
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	&:before, &:after {
		content: '';
		position: absolute;
		background-color: #6c6f70;
		display: block;
	}
	&:before {
		content: '';
		width: 100%;
		height: .2rem;
		top: .5rem;
		left: 0;
	}
	&:after {
		content: '';
		width: .2rem;
		height: 100%;
		left: .5rem;
		top: 0;		
	}		
}
.warning {
  color: #7d5a29;
  background-color: #fcefdc;
  border-color: #fbe8cd;
  border: 1px solid #fbe8cd;
  border-radius: .4rem;
  padding: 12px 20px;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4.5rem;
  .material-icons { margin-right: 2rem; }
  p {
    margin-bottom: 0;
  }
}
.modal-extra-links{
	margin-top: 1rem;
}
.bannerpopup{
    background: #f3f3f3;
    border-radius: 0;
    border: 2px solid;
    width: 100%;
    position: fixed;
    z-index: 90;
    overflow: hidden;
    display: none;
    bottom: 35%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    @media (min-width: 768px) {
        height: 330px;
        max-width: 600px;
    }
    @media (max-width: 767px) {
        height: 240px;
        max-width: 500px;
    }
    @media (max-width: 550px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    &.none{
        display: none;
    }
    .close{
    	position: absolute;
	    right: 0;
	    background-color: transparent;
	    cursor: pointer;
	    z-index: 2;
    }
    .mfp-close{
    	width: 25px;
    	height: 25px;
    	background-color: rgba(0,0,0,.8);
    }
    .contenido{
    	position: absolute;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	z-index: 1;
        @media (max-width: 767px) {
            padding: 2rem;
        }
    }
    .btn-banner{
    	background-color: #000;
    	color: #fff;
    	text-transform: uppercase;
    	font-style: 18px;
    	font-weight: 500;
    	padding: 1rem;
    	cursor: pointer;
    	&:hover{
    		span{
    			color: #000;
    		}
    	}
    }
    h3{
        max-width: 450px;
    }
    h3, h4, p{
    	text-align: center;
    	color: #000;
    }
    h3{
    	font-size: 33px;
    	font-weight: 500;
    	letter-spacing: .1rem;
		text-transform: uppercase;
    	border-bottom: .2rem solid #000;
    	margin: 0 auto 2rem;
    }
    h4{
    	font-size: 24px;
    	margin-bottom: 1rem;
    }
    p{
    	font-size: 20px;
    	margin-bottom: 2rem;
    }
    .btn-banner{
    	letter-spacing: 2px;
    }
    .d-flex{
    	text-align: center;
    }
    @media (max-width: 767px) {
        h3{
            font-size: 20px;
        }
        a{
            font-size: 14px;
        }
    }
    
}



